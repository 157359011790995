import { colors } from '@/styles/colorScheme'
import { typography } from '@/styles/typography'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

export const StyledButton = styled(Button)(() => ({
  width: 'auto',
  backgroundColor: colors.green.primary,
  color: colors.black,
  padding: '8px 16px',
  gap: '10px',
  ...typography.monoBig,
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.24)',
  },
  '&:hover': {
    backgroundColor: '#4EBA7F',
  },
}))
