export enum AlertTypeEnum {
  SEND_FUNDS = '1',
  MONITOR_FUNDS = '2',
  SMART_CONTRACT_EVENTS = '3',
  TX_OUTCOME = '4',
}

export enum EvmAlertTypeEnum {
  EVM_MONITOR_FUNDS = '1',
  EVM_TX_OUTCOME = '2',
  EVM_SMART_CONTRACT_EVENTS = '3',
  COSMOS_MONITOR_FUNDS = '4',
  COSMOS_TX_OUTCOME = '5',
}

export enum EthAlertTypeEnum {
  ETH_MONITOR_FUNDS = '1',
  ETH_TX_OUTCOME = '2',
  ETH_SMART_CONTRACT_EVENTS = '3',
}