// source: src/proto/api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.api.Alert', null, global);
goog.exportSymbol('proto.api.Alert.ChainAlertCase', null, global);
goog.exportSymbol('proto.api.AlertCosmosMonitorFunds', null, global);
goog.exportSymbol('proto.api.AlertCosmosSendFunds', null, global);
goog.exportSymbol('proto.api.AlertCosmosSmartContractEvents', null, global);
goog.exportSymbol('proto.api.AlertCosmosTxOutcome', null, global);
goog.exportSymbol('proto.api.AlertCosmosTxOutcome.CosmosTxOutcome', null, global);
goog.exportSymbol('proto.api.AlertEthMonitorFunds', null, global);
goog.exportSymbol('proto.api.AlertEthSmartContractEvents', null, global);
goog.exportSymbol('proto.api.AlertEthTxOutcome', null, global);
goog.exportSymbol('proto.api.AlertEthTxOutcome.EthTxOutcome', null, global);
goog.exportSymbol('proto.api.AlertNotification', null, global);
goog.exportSymbol('proto.api.AlertNotificationData', null, global);
goog.exportSymbol('proto.api.AlertNotificationData.AlertNotificationDataCase', null, global);
goog.exportSymbol('proto.api.AlertSource', null, global);
goog.exportSymbol('proto.api.Chain', null, global);
goog.exportSymbol('proto.api.Chain.Status', null, global);
goog.exportSymbol('proto.api.ChainData', null, global);
goog.exportSymbol('proto.api.ChainData.ChainDataCase', null, global);
goog.exportSymbol('proto.api.ConfirmDestinationRegistrationRequest', null, global);
goog.exportSymbol('proto.api.ConfirmDestinationRegistrationResponse', null, global);
goog.exportSymbol('proto.api.CosmosAlert', null, global);
goog.exportSymbol('proto.api.CosmosAlert.CosmosAlertCase', null, global);
goog.exportSymbol('proto.api.CosmosChainData', null, global);
goog.exportSymbol('proto.api.CosmosCrawlerData', null, global);
goog.exportSymbol('proto.api.CosmosEvmAlert', null, global);
goog.exportSymbol('proto.api.CosmosEvmAlert.CosmosEvmAlertCase', null, global);
goog.exportSymbol('proto.api.CosmosEvmChainData', null, global);
goog.exportSymbol('proto.api.CosmosEvmCrawlerData', null, global);
goog.exportSymbol('proto.api.CrawlerData', null, global);
goog.exportSymbol('proto.api.CrawlerData.CrawlerDataCase', null, global);
goog.exportSymbol('proto.api.CreateAlertRequest', null, global);
goog.exportSymbol('proto.api.CreateAlertResponse', null, global);
goog.exportSymbol('proto.api.CreateChainRequest', null, global);
goog.exportSymbol('proto.api.CreateChainResponse', null, global);
goog.exportSymbol('proto.api.DeleteAlertRequest', null, global);
goog.exportSymbol('proto.api.DeleteAlertResponse', null, global);
goog.exportSymbol('proto.api.DeleteUserDestinationRequest', null, global);
goog.exportSymbol('proto.api.DeleteUserDestinationResponse', null, global);
goog.exportSymbol('proto.api.Destination', null, global);
goog.exportSymbol('proto.api.Destination.DestinationCase', null, global);
goog.exportSymbol('proto.api.DestinationRegistrationProposal', null, global);
goog.exportSymbol('proto.api.EthAlert', null, global);
goog.exportSymbol('proto.api.EthAlert.EthAlertCase', null, global);
goog.exportSymbol('proto.api.EthChainData', null, global);
goog.exportSymbol('proto.api.EthCrawlerData', null, global);
goog.exportSymbol('proto.api.GetAlertsRequest', null, global);
goog.exportSymbol('proto.api.GetAlertsResponse', null, global);
goog.exportSymbol('proto.api.GetChainsRequest', null, global);
goog.exportSymbol('proto.api.GetChainsResponse', null, global);
goog.exportSymbol('proto.api.GetNotificationsRequest', null, global);
goog.exportSymbol('proto.api.GetNotificationsResponse', null, global);
goog.exportSymbol('proto.api.GetUserDestinationsRequest', null, global);
goog.exportSymbol('proto.api.GetUserDestinationsResponse', null, global);
goog.exportSymbol('proto.api.InitiateDestinationRegistrationRequest', null, global);
goog.exportSymbol('proto.api.InitiateDestinationRegistrationResponse', null, global);
goog.exportSymbol('proto.api.MonitorFundsCoinNotificationData', null, global);
goog.exportSymbol('proto.api.MonitorFundsCoinNotificationData.CoinAmount', null, global);
goog.exportSymbol('proto.api.MonitorFundsTokenNotificationData', null, global);
goog.exportSymbol('proto.api.SmartContractEventsNotificationData', null, global);
goog.exportSymbol('proto.api.SubmitCognitoCodeGrantRequest', null, global);
goog.exportSymbol('proto.api.SubmitCognitoCodeGrantResponse', null, global);
goog.exportSymbol('proto.api.TxOutcomeNotificationData', null, global);
goog.exportSymbol('proto.api.UpdateAlertRequest', null, global);
goog.exportSymbol('proto.api.UpdateAlertResponse', null, global);
goog.exportSymbol('proto.api.UpdateChainRequest', null, global);
goog.exportSymbol('proto.api.UpdateChainResponse', null, global);
goog.exportSymbol('proto.api.UpdateUserDestinationRequest', null, global);
goog.exportSymbol('proto.api.UpdateUserDestinationResponse', null, global);
goog.exportSymbol('proto.api.UserAlert', null, global);
goog.exportSymbol('proto.api.UserAlert.Status', null, global);
goog.exportSymbol('proto.api.UserDestination', null, global);
goog.exportSymbol('proto.api.UserDestination.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAlertRequest.displayName = 'proto.api.UpdateAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateAlertResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateAlertResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateAlertResponse.displayName = 'proto.api.UpdateAlertResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAlertRequest.displayName = 'proto.api.DeleteAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteAlertResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteAlertResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteAlertResponse.displayName = 'proto.api.DeleteAlertResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteUserDestinationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteUserDestinationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteUserDestinationRequest.displayName = 'proto.api.DeleteUserDestinationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DeleteUserDestinationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DeleteUserDestinationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DeleteUserDestinationResponse.displayName = 'proto.api.DeleteUserDestinationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateUserDestinationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateUserDestinationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateUserDestinationRequest.displayName = 'proto.api.UpdateUserDestinationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateUserDestinationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateUserDestinationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateUserDestinationResponse.displayName = 'proto.api.UpdateUserDestinationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetNotificationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetNotificationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetNotificationsRequest.displayName = 'proto.api.GetNotificationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetNotificationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetNotificationsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetNotificationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetNotificationsResponse.displayName = 'proto.api.GetNotificationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SubmitCognitoCodeGrantRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SubmitCognitoCodeGrantRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SubmitCognitoCodeGrantRequest.displayName = 'proto.api.SubmitCognitoCodeGrantRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SubmitCognitoCodeGrantResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SubmitCognitoCodeGrantResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SubmitCognitoCodeGrantResponse.displayName = 'proto.api.SubmitCognitoCodeGrantResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateChainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateChainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateChainRequest.displayName = 'proto.api.CreateChainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateChainResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateChainResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateChainResponse.displayName = 'proto.api.CreateChainResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateChainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateChainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateChainRequest.displayName = 'proto.api.UpdateChainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UpdateChainResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UpdateChainResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UpdateChainResponse.displayName = 'proto.api.UpdateChainResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChainsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetChainsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChainsRequest.displayName = 'proto.api.GetChainsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetChainsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetChainsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetChainsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetChainsResponse.displayName = 'proto.api.GetChainsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Chain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.Chain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Chain.displayName = 'proto.api.Chain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ChainData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.ChainData.oneofGroups_);
};
goog.inherits(proto.api.ChainData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ChainData.displayName = 'proto.api.ChainData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CosmosChainData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CosmosChainData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CosmosChainData.displayName = 'proto.api.CosmosChainData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CosmosEvmChainData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CosmosEvmChainData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CosmosEvmChainData.displayName = 'proto.api.CosmosEvmChainData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.EthChainData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.EthChainData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.EthChainData.displayName = 'proto.api.EthChainData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetUserDestinationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetUserDestinationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetUserDestinationsRequest.displayName = 'proto.api.GetUserDestinationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetUserDestinationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetUserDestinationsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetUserDestinationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetUserDestinationsResponse.displayName = 'proto.api.GetUserDestinationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.InitiateDestinationRegistrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.InitiateDestinationRegistrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.InitiateDestinationRegistrationRequest.displayName = 'proto.api.InitiateDestinationRegistrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.InitiateDestinationRegistrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.InitiateDestinationRegistrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.InitiateDestinationRegistrationResponse.displayName = 'proto.api.InitiateDestinationRegistrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ConfirmDestinationRegistrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ConfirmDestinationRegistrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ConfirmDestinationRegistrationRequest.displayName = 'proto.api.ConfirmDestinationRegistrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.ConfirmDestinationRegistrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.ConfirmDestinationRegistrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.ConfirmDestinationRegistrationResponse.displayName = 'proto.api.ConfirmDestinationRegistrationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UserDestination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.UserDestination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UserDestination.displayName = 'proto.api.UserDestination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.DestinationRegistrationProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.DestinationRegistrationProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.DestinationRegistrationProposal.displayName = 'proto.api.DestinationRegistrationProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAlertsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.GetAlertsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAlertsRequest.displayName = 'proto.api.GetAlertsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.GetAlertsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.GetAlertsResponse.repeatedFields_, null);
};
goog.inherits(proto.api.GetAlertsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.GetAlertsResponse.displayName = 'proto.api.GetAlertsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAlertRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.CreateAlertRequest.repeatedFields_, null);
};
goog.inherits(proto.api.CreateAlertRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAlertRequest.displayName = 'proto.api.CreateAlertRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CreateAlertResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CreateAlertResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CreateAlertResponse.displayName = 'proto.api.CreateAlertResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.UserAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.UserAlert.repeatedFields_, null);
};
goog.inherits(proto.api.UserAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.UserAlert.displayName = 'proto.api.UserAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Alert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.Alert.oneofGroups_);
};
goog.inherits(proto.api.Alert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Alert.displayName = 'proto.api.Alert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CosmosAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.CosmosAlert.oneofGroups_);
};
goog.inherits(proto.api.CosmosAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CosmosAlert.displayName = 'proto.api.CosmosAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CosmosEvmAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.CosmosEvmAlert.oneofGroups_);
};
goog.inherits(proto.api.CosmosEvmAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CosmosEvmAlert.displayName = 'proto.api.CosmosEvmAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.EthAlert = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.EthAlert.oneofGroups_);
};
goog.inherits(proto.api.EthAlert, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.EthAlert.displayName = 'proto.api.EthAlert';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertEthSmartContractEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertEthSmartContractEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertEthSmartContractEvents.displayName = 'proto.api.AlertEthSmartContractEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertEthMonitorFunds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertEthMonitorFunds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertEthMonitorFunds.displayName = 'proto.api.AlertEthMonitorFunds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertEthTxOutcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertEthTxOutcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertEthTxOutcome.displayName = 'proto.api.AlertEthTxOutcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertCosmosSmartContractEvents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertCosmosSmartContractEvents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertCosmosSmartContractEvents.displayName = 'proto.api.AlertCosmosSmartContractEvents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertCosmosMonitorFunds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertCosmosMonitorFunds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertCosmosMonitorFunds.displayName = 'proto.api.AlertCosmosMonitorFunds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertCosmosSendFunds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertCosmosSendFunds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertCosmosSendFunds.displayName = 'proto.api.AlertCosmosSendFunds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertCosmosTxOutcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertCosmosTxOutcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertCosmosTxOutcome.displayName = 'proto.api.AlertCosmosTxOutcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.Destination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.Destination.oneofGroups_);
};
goog.inherits(proto.api.Destination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.Destination.displayName = 'proto.api.Destination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertNotification = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.AlertNotification, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertNotification.displayName = 'proto.api.AlertNotification';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.AlertNotificationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.AlertNotificationData.oneofGroups_);
};
goog.inherits(proto.api.AlertNotificationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.AlertNotificationData.displayName = 'proto.api.AlertNotificationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.SmartContractEventsNotificationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.SmartContractEventsNotificationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.SmartContractEventsNotificationData.displayName = 'proto.api.SmartContractEventsNotificationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.TxOutcomeNotificationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.TxOutcomeNotificationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.TxOutcomeNotificationData.displayName = 'proto.api.TxOutcomeNotificationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MonitorFundsCoinNotificationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.api.MonitorFundsCoinNotificationData.repeatedFields_, null);
};
goog.inherits(proto.api.MonitorFundsCoinNotificationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MonitorFundsCoinNotificationData.displayName = 'proto.api.MonitorFundsCoinNotificationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MonitorFundsCoinNotificationData.CoinAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MonitorFundsCoinNotificationData.CoinAmount.displayName = 'proto.api.MonitorFundsCoinNotificationData.CoinAmount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.MonitorFundsTokenNotificationData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.MonitorFundsTokenNotificationData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.MonitorFundsTokenNotificationData.displayName = 'proto.api.MonitorFundsTokenNotificationData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CrawlerData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.api.CrawlerData.oneofGroups_);
};
goog.inherits(proto.api.CrawlerData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CrawlerData.displayName = 'proto.api.CrawlerData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CosmosCrawlerData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CosmosCrawlerData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CosmosCrawlerData.displayName = 'proto.api.CosmosCrawlerData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.CosmosEvmCrawlerData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.CosmosEvmCrawlerData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.CosmosEvmCrawlerData.displayName = 'proto.api.CosmosEvmCrawlerData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.api.EthCrawlerData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.api.EthCrawlerData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.api.EthCrawlerData.displayName = 'proto.api.EthCrawlerData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: (f = msg.getAlert()) && proto.api.UserAlert.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAlertRequest}
 */
proto.api.UpdateAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAlertRequest;
  return proto.api.UpdateAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAlertRequest}
 */
proto.api.UpdateAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserAlert;
      reader.readMessage(value,proto.api.UserAlert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UserAlert.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserAlert alert = 1;
 * @return {?proto.api.UserAlert}
 */
proto.api.UpdateAlertRequest.prototype.getAlert = function() {
  return /** @type{?proto.api.UserAlert} */ (
    jspb.Message.getWrapperField(this, proto.api.UserAlert, 1));
};


/**
 * @param {?proto.api.UserAlert|undefined} value
 * @return {!proto.api.UpdateAlertRequest} returns this
*/
proto.api.UpdateAlertRequest.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateAlertRequest} returns this
 */
proto.api.UpdateAlertRequest.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateAlertRequest.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateAlertResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateAlertResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateAlertResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAlertResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: (f = msg.getAlert()) && proto.api.UserAlert.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateAlertResponse}
 */
proto.api.UpdateAlertResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateAlertResponse;
  return proto.api.UpdateAlertResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateAlertResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateAlertResponse}
 */
proto.api.UpdateAlertResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserAlert;
      reader.readMessage(value,proto.api.UserAlert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateAlertResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateAlertResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateAlertResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateAlertResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UserAlert.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserAlert alert = 1;
 * @return {?proto.api.UserAlert}
 */
proto.api.UpdateAlertResponse.prototype.getAlert = function() {
  return /** @type{?proto.api.UserAlert} */ (
    jspb.Message.getWrapperField(this, proto.api.UserAlert, 1));
};


/**
 * @param {?proto.api.UserAlert|undefined} value
 * @return {!proto.api.UpdateAlertResponse} returns this
*/
proto.api.UpdateAlertResponse.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateAlertResponse} returns this
 */
proto.api.UpdateAlertResponse.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateAlertResponse.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAlertRequest}
 */
proto.api.DeleteAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAlertRequest;
  return proto.api.DeleteAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAlertRequest}
 */
proto.api.DeleteAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string alert_id = 1;
 * @return {string}
 */
proto.api.DeleteAlertRequest.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteAlertRequest} returns this
 */
proto.api.DeleteAlertRequest.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteAlertResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteAlertResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteAlertResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAlertResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteAlertResponse}
 */
proto.api.DeleteAlertResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteAlertResponse;
  return proto.api.DeleteAlertResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteAlertResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteAlertResponse}
 */
proto.api.DeleteAlertResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteAlertResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteAlertResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteAlertResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteAlertResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteUserDestinationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteUserDestinationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteUserDestinationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteUserDestinationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteUserDestinationRequest}
 */
proto.api.DeleteUserDestinationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteUserDestinationRequest;
  return proto.api.DeleteUserDestinationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteUserDestinationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteUserDestinationRequest}
 */
proto.api.DeleteUserDestinationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteUserDestinationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteUserDestinationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteUserDestinationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteUserDestinationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DeleteUserDestinationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DeleteUserDestinationRequest} returns this
 */
proto.api.DeleteUserDestinationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DeleteUserDestinationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DeleteUserDestinationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DeleteUserDestinationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteUserDestinationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DeleteUserDestinationResponse}
 */
proto.api.DeleteUserDestinationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DeleteUserDestinationResponse;
  return proto.api.DeleteUserDestinationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DeleteUserDestinationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DeleteUserDestinationResponse}
 */
proto.api.DeleteUserDestinationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DeleteUserDestinationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DeleteUserDestinationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DeleteUserDestinationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DeleteUserDestinationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateUserDestinationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateUserDestinationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateUserDestinationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserDestinationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    destination: (f = msg.getDestination()) && proto.api.UserDestination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateUserDestinationRequest}
 */
proto.api.UpdateUserDestinationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateUserDestinationRequest;
  return proto.api.UpdateUserDestinationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateUserDestinationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateUserDestinationRequest}
 */
proto.api.UpdateUserDestinationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserDestination;
      reader.readMessage(value,proto.api.UserDestination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateUserDestinationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateUserDestinationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateUserDestinationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserDestinationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UserDestination.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserDestination destination = 1;
 * @return {?proto.api.UserDestination}
 */
proto.api.UpdateUserDestinationRequest.prototype.getDestination = function() {
  return /** @type{?proto.api.UserDestination} */ (
    jspb.Message.getWrapperField(this, proto.api.UserDestination, 1));
};


/**
 * @param {?proto.api.UserDestination|undefined} value
 * @return {!proto.api.UpdateUserDestinationRequest} returns this
*/
proto.api.UpdateUserDestinationRequest.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateUserDestinationRequest} returns this
 */
proto.api.UpdateUserDestinationRequest.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateUserDestinationRequest.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateUserDestinationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateUserDestinationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateUserDestinationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserDestinationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    destination: (f = msg.getDestination()) && proto.api.UserDestination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateUserDestinationResponse}
 */
proto.api.UpdateUserDestinationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateUserDestinationResponse;
  return proto.api.UpdateUserDestinationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateUserDestinationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateUserDestinationResponse}
 */
proto.api.UpdateUserDestinationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserDestination;
      reader.readMessage(value,proto.api.UserDestination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateUserDestinationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateUserDestinationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateUserDestinationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateUserDestinationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UserDestination.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserDestination destination = 1;
 * @return {?proto.api.UserDestination}
 */
proto.api.UpdateUserDestinationResponse.prototype.getDestination = function() {
  return /** @type{?proto.api.UserDestination} */ (
    jspb.Message.getWrapperField(this, proto.api.UserDestination, 1));
};


/**
 * @param {?proto.api.UserDestination|undefined} value
 * @return {!proto.api.UpdateUserDestinationResponse} returns this
*/
proto.api.UpdateUserDestinationResponse.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateUserDestinationResponse} returns this
 */
proto.api.UpdateUserDestinationResponse.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateUserDestinationResponse.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetNotificationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetNotificationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetNotificationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetNotificationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    alertId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetNotificationsRequest}
 */
proto.api.GetNotificationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetNotificationsRequest;
  return proto.api.GetNotificationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetNotificationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetNotificationsRequest}
 */
proto.api.GetNotificationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetNotificationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetNotificationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetNotificationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetNotificationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.api.GetNotificationsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetNotificationsRequest} returns this
 */
proto.api.GetNotificationsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string alert_id = 2;
 * @return {string}
 */
proto.api.GetNotificationsRequest.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetNotificationsRequest} returns this
 */
proto.api.GetNotificationsRequest.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetNotificationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetNotificationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetNotificationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetNotificationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetNotificationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    notificationsList: jspb.Message.toObjectList(msg.getNotificationsList(),
    proto.api.AlertNotification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetNotificationsResponse}
 */
proto.api.GetNotificationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetNotificationsResponse;
  return proto.api.GetNotificationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetNotificationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetNotificationsResponse}
 */
proto.api.GetNotificationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AlertNotification;
      reader.readMessage(value,proto.api.AlertNotification.deserializeBinaryFromReader);
      msg.addNotifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetNotificationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetNotificationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetNotificationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetNotificationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNotificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.AlertNotification.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AlertNotification notifications = 1;
 * @return {!Array<!proto.api.AlertNotification>}
 */
proto.api.GetNotificationsResponse.prototype.getNotificationsList = function() {
  return /** @type{!Array<!proto.api.AlertNotification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.AlertNotification, 1));
};


/**
 * @param {!Array<!proto.api.AlertNotification>} value
 * @return {!proto.api.GetNotificationsResponse} returns this
*/
proto.api.GetNotificationsResponse.prototype.setNotificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.AlertNotification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.AlertNotification}
 */
proto.api.GetNotificationsResponse.prototype.addNotifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.AlertNotification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetNotificationsResponse} returns this
 */
proto.api.GetNotificationsResponse.prototype.clearNotificationsList = function() {
  return this.setNotificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SubmitCognitoCodeGrantRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SubmitCognitoCodeGrantRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SubmitCognitoCodeGrantRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SubmitCognitoCodeGrantRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SubmitCognitoCodeGrantRequest}
 */
proto.api.SubmitCognitoCodeGrantRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SubmitCognitoCodeGrantRequest;
  return proto.api.SubmitCognitoCodeGrantRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SubmitCognitoCodeGrantRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SubmitCognitoCodeGrantRequest}
 */
proto.api.SubmitCognitoCodeGrantRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SubmitCognitoCodeGrantRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SubmitCognitoCodeGrantRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SubmitCognitoCodeGrantRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SubmitCognitoCodeGrantRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.api.SubmitCognitoCodeGrantRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubmitCognitoCodeGrantRequest} returns this
 */
proto.api.SubmitCognitoCodeGrantRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SubmitCognitoCodeGrantResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SubmitCognitoCodeGrantResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SubmitCognitoCodeGrantResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SubmitCognitoCodeGrantResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SubmitCognitoCodeGrantResponse}
 */
proto.api.SubmitCognitoCodeGrantResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SubmitCognitoCodeGrantResponse;
  return proto.api.SubmitCognitoCodeGrantResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SubmitCognitoCodeGrantResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SubmitCognitoCodeGrantResponse}
 */
proto.api.SubmitCognitoCodeGrantResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SubmitCognitoCodeGrantResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SubmitCognitoCodeGrantResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SubmitCognitoCodeGrantResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SubmitCognitoCodeGrantResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id_token = 1;
 * @return {string}
 */
proto.api.SubmitCognitoCodeGrantResponse.prototype.getIdToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SubmitCognitoCodeGrantResponse} returns this
 */
proto.api.SubmitCognitoCodeGrantResponse.prototype.setIdToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateChainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateChainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateChainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chainData: (f = msg.getChainData()) && proto.api.ChainData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateChainRequest}
 */
proto.api.CreateChainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateChainRequest;
  return proto.api.CreateChainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateChainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateChainRequest}
 */
proto.api.CreateChainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 6:
      var value = new proto.api.ChainData;
      reader.readMessage(value,proto.api.ChainData.deserializeBinaryFromReader);
      msg.setChainData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateChainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateChainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateChainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChainData();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.api.ChainData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.CreateChainRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateChainRequest} returns this
 */
proto.api.CreateChainRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.api.CreateChainRequest.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateChainRequest} returns this
 */
proto.api.CreateChainRequest.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ChainData chain_data = 6;
 * @return {?proto.api.ChainData}
 */
proto.api.CreateChainRequest.prototype.getChainData = function() {
  return /** @type{?proto.api.ChainData} */ (
    jspb.Message.getWrapperField(this, proto.api.ChainData, 6));
};


/**
 * @param {?proto.api.ChainData|undefined} value
 * @return {!proto.api.CreateChainRequest} returns this
*/
proto.api.CreateChainRequest.prototype.setChainData = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChainRequest} returns this
 */
proto.api.CreateChainRequest.prototype.clearChainData = function() {
  return this.setChainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChainRequest.prototype.hasChainData = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateChainResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateChainResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateChainResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChainResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chain: (f = msg.getChain()) && proto.api.Chain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateChainResponse}
 */
proto.api.CreateChainResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateChainResponse;
  return proto.api.CreateChainResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateChainResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateChainResponse}
 */
proto.api.CreateChainResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Chain;
      reader.readMessage(value,proto.api.Chain.deserializeBinaryFromReader);
      msg.setChain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateChainResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateChainResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateChainResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateChainResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Chain.serializeBinaryToWriter
    );
  }
};


/**
 * optional Chain chain = 1;
 * @return {?proto.api.Chain}
 */
proto.api.CreateChainResponse.prototype.getChain = function() {
  return /** @type{?proto.api.Chain} */ (
    jspb.Message.getWrapperField(this, proto.api.Chain, 1));
};


/**
 * @param {?proto.api.Chain|undefined} value
 * @return {!proto.api.CreateChainResponse} returns this
*/
proto.api.CreateChainResponse.prototype.setChain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateChainResponse} returns this
 */
proto.api.CreateChainResponse.prototype.clearChain = function() {
  return this.setChain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateChainResponse.prototype.hasChain = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateChainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateChainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateChainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateChainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chain: (f = msg.getChain()) && proto.api.Chain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateChainRequest}
 */
proto.api.UpdateChainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateChainRequest;
  return proto.api.UpdateChainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateChainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateChainRequest}
 */
proto.api.UpdateChainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Chain;
      reader.readMessage(value,proto.api.Chain.deserializeBinaryFromReader);
      msg.setChain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateChainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateChainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateChainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateChainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Chain.serializeBinaryToWriter
    );
  }
};


/**
 * optional Chain chain = 1;
 * @return {?proto.api.Chain}
 */
proto.api.UpdateChainRequest.prototype.getChain = function() {
  return /** @type{?proto.api.Chain} */ (
    jspb.Message.getWrapperField(this, proto.api.Chain, 1));
};


/**
 * @param {?proto.api.Chain|undefined} value
 * @return {!proto.api.UpdateChainRequest} returns this
*/
proto.api.UpdateChainRequest.prototype.setChain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateChainRequest} returns this
 */
proto.api.UpdateChainRequest.prototype.clearChain = function() {
  return this.setChain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateChainRequest.prototype.hasChain = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UpdateChainResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UpdateChainResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UpdateChainResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateChainResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chain: (f = msg.getChain()) && proto.api.Chain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UpdateChainResponse}
 */
proto.api.UpdateChainResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UpdateChainResponse;
  return proto.api.UpdateChainResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UpdateChainResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UpdateChainResponse}
 */
proto.api.UpdateChainResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Chain;
      reader.readMessage(value,proto.api.Chain.deserializeBinaryFromReader);
      msg.setChain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UpdateChainResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UpdateChainResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UpdateChainResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UpdateChainResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.Chain.serializeBinaryToWriter
    );
  }
};


/**
 * optional Chain chain = 1;
 * @return {?proto.api.Chain}
 */
proto.api.UpdateChainResponse.prototype.getChain = function() {
  return /** @type{?proto.api.Chain} */ (
    jspb.Message.getWrapperField(this, proto.api.Chain, 1));
};


/**
 * @param {?proto.api.Chain|undefined} value
 * @return {!proto.api.UpdateChainResponse} returns this
*/
proto.api.UpdateChainResponse.prototype.setChain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UpdateChainResponse} returns this
 */
proto.api.UpdateChainResponse.prototype.clearChain = function() {
  return this.setChain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UpdateChainResponse.prototype.hasChain = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChainsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChainsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChainsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChainsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChainsRequest}
 */
proto.api.GetChainsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChainsRequest;
  return proto.api.GetChainsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChainsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChainsRequest}
 */
proto.api.GetChainsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChainsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChainsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChainsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChainsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetChainsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetChainsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetChainsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetChainsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChainsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainsList: jspb.Message.toObjectList(msg.getChainsList(),
    proto.api.Chain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetChainsResponse}
 */
proto.api.GetChainsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetChainsResponse;
  return proto.api.GetChainsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetChainsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetChainsResponse}
 */
proto.api.GetChainsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.Chain;
      reader.readMessage(value,proto.api.Chain.deserializeBinaryFromReader);
      msg.addChains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetChainsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetChainsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetChainsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetChainsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.Chain.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Chain chains = 1;
 * @return {!Array<!proto.api.Chain>}
 */
proto.api.GetChainsResponse.prototype.getChainsList = function() {
  return /** @type{!Array<!proto.api.Chain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.Chain, 1));
};


/**
 * @param {!Array<!proto.api.Chain>} value
 * @return {!proto.api.GetChainsResponse} returns this
*/
proto.api.GetChainsResponse.prototype.setChainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.Chain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.Chain}
 */
proto.api.GetChainsResponse.prototype.addChains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.Chain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetChainsResponse} returns this
 */
proto.api.GetChainsResponse.prototype.clearChainsList = function() {
  return this.setChainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Chain.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Chain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Chain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Chain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    chainData: (f = msg.getChainData()) && proto.api.ChainData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Chain}
 */
proto.api.Chain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Chain;
  return proto.api.Chain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Chain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Chain}
 */
proto.api.Chain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = /** @type {!proto.api.Chain.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new proto.api.ChainData;
      reader.readMessage(value,proto.api.ChainData.deserializeBinaryFromReader);
      msg.setChainData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Chain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Chain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Chain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Chain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getChainData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.ChainData.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.api.Chain.Status = {
  ENABLED: 0,
  DISABLED: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.Chain.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Chain} returns this
 */
proto.api.Chain.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.api.Chain.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Chain} returns this
 */
proto.api.Chain.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.api.Chain.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Chain} returns this
 */
proto.api.Chain.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Status status = 4;
 * @return {!proto.api.Chain.Status}
 */
proto.api.Chain.prototype.getStatus = function() {
  return /** @type {!proto.api.Chain.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.api.Chain.Status} value
 * @return {!proto.api.Chain} returns this
 */
proto.api.Chain.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional ChainData chain_data = 5;
 * @return {?proto.api.ChainData}
 */
proto.api.Chain.prototype.getChainData = function() {
  return /** @type{?proto.api.ChainData} */ (
    jspb.Message.getWrapperField(this, proto.api.ChainData, 5));
};


/**
 * @param {?proto.api.ChainData|undefined} value
 * @return {!proto.api.Chain} returns this
*/
proto.api.Chain.prototype.setChainData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Chain} returns this
 */
proto.api.Chain.prototype.clearChainData = function() {
  return this.setChainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Chain.prototype.hasChainData = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.ChainData.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.ChainData.ChainDataCase = {
  CHAIN_DATA_NOT_SET: 0,
  COSMOS_CHAIN_DATA: 1,
  COSMOS_EVM_CHAIN_DATA: 2,
  ETH_CHAIN_DATA: 3
};

/**
 * @return {proto.api.ChainData.ChainDataCase}
 */
proto.api.ChainData.prototype.getChainDataCase = function() {
  return /** @type {proto.api.ChainData.ChainDataCase} */(jspb.Message.computeOneofCase(this, proto.api.ChainData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ChainData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ChainData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ChainData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChainData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cosmosChainData: (f = msg.getCosmosChainData()) && proto.api.CosmosChainData.toObject(includeInstance, f),
    cosmosEvmChainData: (f = msg.getCosmosEvmChainData()) && proto.api.CosmosEvmChainData.toObject(includeInstance, f),
    ethChainData: (f = msg.getEthChainData()) && proto.api.EthChainData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ChainData}
 */
proto.api.ChainData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ChainData;
  return proto.api.ChainData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ChainData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ChainData}
 */
proto.api.ChainData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CosmosChainData;
      reader.readMessage(value,proto.api.CosmosChainData.deserializeBinaryFromReader);
      msg.setCosmosChainData(value);
      break;
    case 2:
      var value = new proto.api.CosmosEvmChainData;
      reader.readMessage(value,proto.api.CosmosEvmChainData.deserializeBinaryFromReader);
      msg.setCosmosEvmChainData(value);
      break;
    case 3:
      var value = new proto.api.EthChainData;
      reader.readMessage(value,proto.api.EthChainData.deserializeBinaryFromReader);
      msg.setEthChainData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ChainData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ChainData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ChainData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ChainData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCosmosChainData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.CosmosChainData.serializeBinaryToWriter
    );
  }
  f = message.getCosmosEvmChainData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.CosmosEvmChainData.serializeBinaryToWriter
    );
  }
  f = message.getEthChainData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.EthChainData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CosmosChainData cosmos_chain_data = 1;
 * @return {?proto.api.CosmosChainData}
 */
proto.api.ChainData.prototype.getCosmosChainData = function() {
  return /** @type{?proto.api.CosmosChainData} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosChainData, 1));
};


/**
 * @param {?proto.api.CosmosChainData|undefined} value
 * @return {!proto.api.ChainData} returns this
*/
proto.api.ChainData.prototype.setCosmosChainData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.ChainData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ChainData} returns this
 */
proto.api.ChainData.prototype.clearCosmosChainData = function() {
  return this.setCosmosChainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChainData.prototype.hasCosmosChainData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CosmosEvmChainData cosmos_evm_chain_data = 2;
 * @return {?proto.api.CosmosEvmChainData}
 */
proto.api.ChainData.prototype.getCosmosEvmChainData = function() {
  return /** @type{?proto.api.CosmosEvmChainData} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosEvmChainData, 2));
};


/**
 * @param {?proto.api.CosmosEvmChainData|undefined} value
 * @return {!proto.api.ChainData} returns this
*/
proto.api.ChainData.prototype.setCosmosEvmChainData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.ChainData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ChainData} returns this
 */
proto.api.ChainData.prototype.clearCosmosEvmChainData = function() {
  return this.setCosmosEvmChainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChainData.prototype.hasCosmosEvmChainData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EthChainData eth_chain_data = 3;
 * @return {?proto.api.EthChainData}
 */
proto.api.ChainData.prototype.getEthChainData = function() {
  return /** @type{?proto.api.EthChainData} */ (
    jspb.Message.getWrapperField(this, proto.api.EthChainData, 3));
};


/**
 * @param {?proto.api.EthChainData|undefined} value
 * @return {!proto.api.ChainData} returns this
*/
proto.api.ChainData.prototype.setEthChainData = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.api.ChainData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ChainData} returns this
 */
proto.api.ChainData.prototype.clearEthChainData = function() {
  return this.setEthChainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ChainData.prototype.hasEthChainData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CosmosChainData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CosmosChainData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CosmosChainData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosChainData.toObject = function(includeInstance, msg) {
  var f, obj = {
    grpcEndpoint: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bech32Prefix: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CosmosChainData}
 */
proto.api.CosmosChainData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CosmosChainData;
  return proto.api.CosmosChainData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CosmosChainData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CosmosChainData}
 */
proto.api.CosmosChainData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrpcEndpoint(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBech32Prefix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CosmosChainData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CosmosChainData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CosmosChainData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosChainData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGrpcEndpoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBech32Prefix();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string grpc_endpoint = 1;
 * @return {string}
 */
proto.api.CosmosChainData.prototype.getGrpcEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CosmosChainData} returns this
 */
proto.api.CosmosChainData.prototype.setGrpcEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bech32_prefix = 2;
 * @return {string}
 */
proto.api.CosmosChainData.prototype.getBech32Prefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CosmosChainData} returns this
 */
proto.api.CosmosChainData.prototype.setBech32Prefix = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CosmosEvmChainData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CosmosEvmChainData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CosmosEvmChainData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosEvmChainData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cosmosChainData: (f = msg.getCosmosChainData()) && proto.api.CosmosChainData.toObject(includeInstance, f),
    ethChainData: (f = msg.getEthChainData()) && proto.api.EthChainData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CosmosEvmChainData}
 */
proto.api.CosmosEvmChainData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CosmosEvmChainData;
  return proto.api.CosmosEvmChainData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CosmosEvmChainData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CosmosEvmChainData}
 */
proto.api.CosmosEvmChainData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CosmosChainData;
      reader.readMessage(value,proto.api.CosmosChainData.deserializeBinaryFromReader);
      msg.setCosmosChainData(value);
      break;
    case 2:
      var value = new proto.api.EthChainData;
      reader.readMessage(value,proto.api.EthChainData.deserializeBinaryFromReader);
      msg.setEthChainData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CosmosEvmChainData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CosmosEvmChainData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CosmosEvmChainData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosEvmChainData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCosmosChainData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.CosmosChainData.serializeBinaryToWriter
    );
  }
  f = message.getEthChainData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.EthChainData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CosmosChainData cosmos_chain_data = 1;
 * @return {?proto.api.CosmosChainData}
 */
proto.api.CosmosEvmChainData.prototype.getCosmosChainData = function() {
  return /** @type{?proto.api.CosmosChainData} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosChainData, 1));
};


/**
 * @param {?proto.api.CosmosChainData|undefined} value
 * @return {!proto.api.CosmosEvmChainData} returns this
*/
proto.api.CosmosEvmChainData.prototype.setCosmosChainData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmChainData} returns this
 */
proto.api.CosmosEvmChainData.prototype.clearCosmosChainData = function() {
  return this.setCosmosChainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmChainData.prototype.hasCosmosChainData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EthChainData eth_chain_data = 2;
 * @return {?proto.api.EthChainData}
 */
proto.api.CosmosEvmChainData.prototype.getEthChainData = function() {
  return /** @type{?proto.api.EthChainData} */ (
    jspb.Message.getWrapperField(this, proto.api.EthChainData, 2));
};


/**
 * @param {?proto.api.EthChainData|undefined} value
 * @return {!proto.api.CosmosEvmChainData} returns this
*/
proto.api.CosmosEvmChainData.prototype.setEthChainData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmChainData} returns this
 */
proto.api.CosmosEvmChainData.prototype.clearEthChainData = function() {
  return this.setEthChainData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmChainData.prototype.hasEthChainData = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.EthChainData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.EthChainData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.EthChainData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.EthChainData.toObject = function(includeInstance, msg) {
  var f, obj = {
    ethRpcEndpoint: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.EthChainData}
 */
proto.api.EthChainData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.EthChainData;
  return proto.api.EthChainData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.EthChainData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.EthChainData}
 */
proto.api.EthChainData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEthRpcEndpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.EthChainData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.EthChainData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.EthChainData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.EthChainData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEthRpcEndpoint();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string eth_rpc_endpoint = 1;
 * @return {string}
 */
proto.api.EthChainData.prototype.getEthRpcEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.EthChainData} returns this
 */
proto.api.EthChainData.prototype.setEthRpcEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetUserDestinationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetUserDestinationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetUserDestinationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUserDestinationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetUserDestinationsRequest}
 */
proto.api.GetUserDestinationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetUserDestinationsRequest;
  return proto.api.GetUserDestinationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetUserDestinationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetUserDestinationsRequest}
 */
proto.api.GetUserDestinationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetUserDestinationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetUserDestinationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetUserDestinationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUserDestinationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.GetUserDestinationsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetUserDestinationsRequest} returns this
 */
proto.api.GetUserDestinationsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 page = 2;
 * @return {number}
 */
proto.api.GetUserDestinationsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetUserDestinationsRequest} returns this
 */
proto.api.GetUserDestinationsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetUserDestinationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetUserDestinationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetUserDestinationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetUserDestinationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUserDestinationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userDestinationsList: jspb.Message.toObjectList(msg.getUserDestinationsList(),
    proto.api.UserDestination.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetUserDestinationsResponse}
 */
proto.api.GetUserDestinationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetUserDestinationsResponse;
  return proto.api.GetUserDestinationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetUserDestinationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetUserDestinationsResponse}
 */
proto.api.GetUserDestinationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserDestination;
      reader.readMessage(value,proto.api.UserDestination.deserializeBinaryFromReader);
      msg.addUserDestinations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetUserDestinationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetUserDestinationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetUserDestinationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetUserDestinationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserDestinationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.UserDestination.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserDestination user_destinations = 1;
 * @return {!Array<!proto.api.UserDestination>}
 */
proto.api.GetUserDestinationsResponse.prototype.getUserDestinationsList = function() {
  return /** @type{!Array<!proto.api.UserDestination>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.UserDestination, 1));
};


/**
 * @param {!Array<!proto.api.UserDestination>} value
 * @return {!proto.api.GetUserDestinationsResponse} returns this
*/
proto.api.GetUserDestinationsResponse.prototype.setUserDestinationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.UserDestination=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.UserDestination}
 */
proto.api.GetUserDestinationsResponse.prototype.addUserDestinations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.UserDestination, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetUserDestinationsResponse} returns this
 */
proto.api.GetUserDestinationsResponse.prototype.clearUserDestinationsList = function() {
  return this.setUserDestinationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.InitiateDestinationRegistrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.InitiateDestinationRegistrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.InitiateDestinationRegistrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.InitiateDestinationRegistrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    destination: (f = msg.getDestination()) && proto.api.Destination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.InitiateDestinationRegistrationRequest}
 */
proto.api.InitiateDestinationRegistrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.InitiateDestinationRegistrationRequest;
  return proto.api.InitiateDestinationRegistrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.InitiateDestinationRegistrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.InitiateDestinationRegistrationRequest}
 */
proto.api.InitiateDestinationRegistrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.api.Destination;
      reader.readMessage(value,proto.api.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.InitiateDestinationRegistrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.InitiateDestinationRegistrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.InitiateDestinationRegistrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.InitiateDestinationRegistrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.Destination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.InitiateDestinationRegistrationRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.InitiateDestinationRegistrationRequest} returns this
 */
proto.api.InitiateDestinationRegistrationRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Destination destination = 2;
 * @return {?proto.api.Destination}
 */
proto.api.InitiateDestinationRegistrationRequest.prototype.getDestination = function() {
  return /** @type{?proto.api.Destination} */ (
    jspb.Message.getWrapperField(this, proto.api.Destination, 2));
};


/**
 * @param {?proto.api.Destination|undefined} value
 * @return {!proto.api.InitiateDestinationRegistrationRequest} returns this
*/
proto.api.InitiateDestinationRegistrationRequest.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.InitiateDestinationRegistrationRequest} returns this
 */
proto.api.InitiateDestinationRegistrationRequest.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.InitiateDestinationRegistrationRequest.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.InitiateDestinationRegistrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.InitiateDestinationRegistrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.InitiateDestinationRegistrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.InitiateDestinationRegistrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationRegistrationProposalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.InitiateDestinationRegistrationResponse}
 */
proto.api.InitiateDestinationRegistrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.InitiateDestinationRegistrationResponse;
  return proto.api.InitiateDestinationRegistrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.InitiateDestinationRegistrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.InitiateDestinationRegistrationResponse}
 */
proto.api.InitiateDestinationRegistrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationRegistrationProposalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.InitiateDestinationRegistrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.InitiateDestinationRegistrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.InitiateDestinationRegistrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.InitiateDestinationRegistrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationRegistrationProposalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string destination_registration_proposal_id = 1;
 * @return {string}
 */
proto.api.InitiateDestinationRegistrationResponse.prototype.getDestinationRegistrationProposalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.InitiateDestinationRegistrationResponse} returns this
 */
proto.api.InitiateDestinationRegistrationResponse.prototype.setDestinationRegistrationProposalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ConfirmDestinationRegistrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ConfirmDestinationRegistrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ConfirmDestinationRegistrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConfirmDestinationRegistrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    destinationRegistrationProposalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    confirmationCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ConfirmDestinationRegistrationRequest}
 */
proto.api.ConfirmDestinationRegistrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ConfirmDestinationRegistrationRequest;
  return proto.api.ConfirmDestinationRegistrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ConfirmDestinationRegistrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ConfirmDestinationRegistrationRequest}
 */
proto.api.ConfirmDestinationRegistrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationRegistrationProposalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ConfirmDestinationRegistrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ConfirmDestinationRegistrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ConfirmDestinationRegistrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConfirmDestinationRegistrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestinationRegistrationProposalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfirmationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string destination_registration_proposal_id = 1;
 * @return {string}
 */
proto.api.ConfirmDestinationRegistrationRequest.prototype.getDestinationRegistrationProposalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ConfirmDestinationRegistrationRequest} returns this
 */
proto.api.ConfirmDestinationRegistrationRequest.prototype.setDestinationRegistrationProposalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string confirmation_code = 2;
 * @return {string}
 */
proto.api.ConfirmDestinationRegistrationRequest.prototype.getConfirmationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.ConfirmDestinationRegistrationRequest} returns this
 */
proto.api.ConfirmDestinationRegistrationRequest.prototype.setConfirmationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.ConfirmDestinationRegistrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.ConfirmDestinationRegistrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.ConfirmDestinationRegistrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConfirmDestinationRegistrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    destination: (f = msg.getDestination()) && proto.api.UserDestination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.ConfirmDestinationRegistrationResponse}
 */
proto.api.ConfirmDestinationRegistrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.ConfirmDestinationRegistrationResponse;
  return proto.api.ConfirmDestinationRegistrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.ConfirmDestinationRegistrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.ConfirmDestinationRegistrationResponse}
 */
proto.api.ConfirmDestinationRegistrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserDestination;
      reader.readMessage(value,proto.api.UserDestination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.ConfirmDestinationRegistrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.ConfirmDestinationRegistrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.ConfirmDestinationRegistrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.ConfirmDestinationRegistrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UserDestination.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserDestination destination = 1;
 * @return {?proto.api.UserDestination}
 */
proto.api.ConfirmDestinationRegistrationResponse.prototype.getDestination = function() {
  return /** @type{?proto.api.UserDestination} */ (
    jspb.Message.getWrapperField(this, proto.api.UserDestination, 1));
};


/**
 * @param {?proto.api.UserDestination|undefined} value
 * @return {!proto.api.ConfirmDestinationRegistrationResponse} returns this
*/
proto.api.ConfirmDestinationRegistrationResponse.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.ConfirmDestinationRegistrationResponse} returns this
 */
proto.api.ConfirmDestinationRegistrationResponse.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.ConfirmDestinationRegistrationResponse.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UserDestination.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UserDestination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UserDestination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserDestination.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    destination: (f = msg.getDestination()) && proto.api.Destination.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UserDestination}
 */
proto.api.UserDestination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UserDestination;
  return proto.api.UserDestination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UserDestination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UserDestination}
 */
proto.api.UserDestination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = new proto.api.Destination;
      reader.readMessage(value,proto.api.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    case 4:
      var value = /** @type {!proto.api.UserDestination.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UserDestination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UserDestination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UserDestination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserDestination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.Destination.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.UserDestination.Status = {
  ENABLED: 0,
  DISABLED: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UserDestination.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserDestination} returns this
 */
proto.api.UserDestination.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.UserDestination.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserDestination} returns this
 */
proto.api.UserDestination.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Destination Destination = 3;
 * @return {?proto.api.Destination}
 */
proto.api.UserDestination.prototype.getDestination = function() {
  return /** @type{?proto.api.Destination} */ (
    jspb.Message.getWrapperField(this, proto.api.Destination, 3));
};


/**
 * @param {?proto.api.Destination|undefined} value
 * @return {!proto.api.UserDestination} returns this
*/
proto.api.UserDestination.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UserDestination} returns this
 */
proto.api.UserDestination.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UserDestination.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 4;
 * @return {!proto.api.UserDestination.Status}
 */
proto.api.UserDestination.prototype.getStatus = function() {
  return /** @type {!proto.api.UserDestination.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.api.UserDestination.Status} value
 * @return {!proto.api.UserDestination} returns this
 */
proto.api.UserDestination.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.DestinationRegistrationProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.api.DestinationRegistrationProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.DestinationRegistrationProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DestinationRegistrationProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    confirmationCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destination: (f = msg.getDestination()) && proto.api.Destination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.DestinationRegistrationProposal}
 */
proto.api.DestinationRegistrationProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.DestinationRegistrationProposal;
  return proto.api.DestinationRegistrationProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.DestinationRegistrationProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.DestinationRegistrationProposal}
 */
proto.api.DestinationRegistrationProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationCode(value);
      break;
    case 4:
      var value = new proto.api.Destination;
      reader.readMessage(value,proto.api.Destination.deserializeBinaryFromReader);
      msg.setDestination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.DestinationRegistrationProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.DestinationRegistrationProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.DestinationRegistrationProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.DestinationRegistrationProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfirmationCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.Destination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.DestinationRegistrationProposal.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DestinationRegistrationProposal} returns this
 */
proto.api.DestinationRegistrationProposal.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.DestinationRegistrationProposal.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DestinationRegistrationProposal} returns this
 */
proto.api.DestinationRegistrationProposal.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string confirmation_code = 3;
 * @return {string}
 */
proto.api.DestinationRegistrationProposal.prototype.getConfirmationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.DestinationRegistrationProposal} returns this
 */
proto.api.DestinationRegistrationProposal.prototype.setConfirmationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Destination destination = 4;
 * @return {?proto.api.Destination}
 */
proto.api.DestinationRegistrationProposal.prototype.getDestination = function() {
  return /** @type{?proto.api.Destination} */ (
    jspb.Message.getWrapperField(this, proto.api.Destination, 4));
};


/**
 * @param {?proto.api.Destination|undefined} value
 * @return {!proto.api.DestinationRegistrationProposal} returns this
*/
proto.api.DestinationRegistrationProposal.prototype.setDestination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.DestinationRegistrationProposal} returns this
 */
proto.api.DestinationRegistrationProposal.prototype.clearDestination = function() {
  return this.setDestination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.DestinationRegistrationProposal.prototype.hasDestination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAlertsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAlertsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAlertsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAlertsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAlertsRequest}
 */
proto.api.GetAlertsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAlertsRequest;
  return proto.api.GetAlertsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAlertsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAlertsRequest}
 */
proto.api.GetAlertsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAlertsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAlertsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAlertsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAlertsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 page = 1;
 * @return {number}
 */
proto.api.GetAlertsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.GetAlertsRequest} returns this
 */
proto.api.GetAlertsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.GetAlertsRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.GetAlertsRequest} returns this
 */
proto.api.GetAlertsRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.GetAlertsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.GetAlertsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.GetAlertsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.GetAlertsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAlertsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertsList: jspb.Message.toObjectList(msg.getAlertsList(),
    proto.api.UserAlert.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.GetAlertsResponse}
 */
proto.api.GetAlertsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.GetAlertsResponse;
  return proto.api.GetAlertsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.GetAlertsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.GetAlertsResponse}
 */
proto.api.GetAlertsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserAlert;
      reader.readMessage(value,proto.api.UserAlert.deserializeBinaryFromReader);
      msg.addAlerts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.GetAlertsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.GetAlertsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.GetAlertsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.GetAlertsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.api.UserAlert.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserAlert alerts = 1;
 * @return {!Array<!proto.api.UserAlert>}
 */
proto.api.GetAlertsResponse.prototype.getAlertsList = function() {
  return /** @type{!Array<!proto.api.UserAlert>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.UserAlert, 1));
};


/**
 * @param {!Array<!proto.api.UserAlert>} value
 * @return {!proto.api.GetAlertsResponse} returns this
*/
proto.api.GetAlertsResponse.prototype.setAlertsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.api.UserAlert=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.UserAlert}
 */
proto.api.GetAlertsResponse.prototype.addAlerts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.api.UserAlert, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.GetAlertsResponse} returns this
 */
proto.api.GetAlertsResponse.prototype.clearAlertsList = function() {
  return this.setAlertsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.CreateAlertRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAlertRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAlertRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAlertRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAlertRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alert: (f = msg.getAlert()) && proto.api.Alert.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    destinationIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    chainId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAlertRequest}
 */
proto.api.CreateAlertRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAlertRequest;
  return proto.api.CreateAlertRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAlertRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAlertRequest}
 */
proto.api.CreateAlertRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.api.Alert;
      reader.readMessage(value,proto.api.Alert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationIds(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setChainId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAlertRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAlertRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAlertRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAlertRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.Alert.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDestinationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getChainId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.api.CreateAlertRequest.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Alert alert = 2;
 * @return {?proto.api.Alert}
 */
proto.api.CreateAlertRequest.prototype.getAlert = function() {
  return /** @type{?proto.api.Alert} */ (
    jspb.Message.getWrapperField(this, proto.api.Alert, 2));
};


/**
 * @param {?proto.api.Alert|undefined} value
 * @return {!proto.api.CreateAlertRequest} returns this
*/
proto.api.CreateAlertRequest.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAlertRequest.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.api.CreateAlertRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string destination_ids = 4;
 * @return {!Array<string>}
 */
proto.api.CreateAlertRequest.prototype.getDestinationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.setDestinationIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.addDestinationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.clearDestinationIdsList = function() {
  return this.setDestinationIdsList([]);
};


/**
 * optional string chain_id = 5;
 * @return {string}
 */
proto.api.CreateAlertRequest.prototype.getChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.setChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.api.CreateAlertRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.CreateAlertRequest} returns this
 */
proto.api.CreateAlertRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CreateAlertResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CreateAlertResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CreateAlertResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAlertResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    alert: (f = msg.getAlert()) && proto.api.UserAlert.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CreateAlertResponse}
 */
proto.api.CreateAlertResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CreateAlertResponse;
  return proto.api.CreateAlertResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CreateAlertResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CreateAlertResponse}
 */
proto.api.CreateAlertResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.UserAlert;
      reader.readMessage(value,proto.api.UserAlert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CreateAlertResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CreateAlertResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CreateAlertResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CreateAlertResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.UserAlert.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserAlert alert = 1;
 * @return {?proto.api.UserAlert}
 */
proto.api.CreateAlertResponse.prototype.getAlert = function() {
  return /** @type{?proto.api.UserAlert} */ (
    jspb.Message.getWrapperField(this, proto.api.UserAlert, 1));
};


/**
 * @param {?proto.api.UserAlert|undefined} value
 * @return {!proto.api.CreateAlertResponse} returns this
*/
proto.api.CreateAlertResponse.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CreateAlertResponse} returns this
 */
proto.api.CreateAlertResponse.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CreateAlertResponse.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.UserAlert.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.UserAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.api.UserAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.UserAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    alert: (f = msg.getAlert()) && proto.api.Alert.toObject(includeInstance, f),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    destinationIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    chainId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    alertSource: jspb.Message.getFieldWithDefault(msg, 8, 0),
    name: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.UserAlert}
 */
proto.api.UserAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.UserAlert;
  return proto.api.UserAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.UserAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.UserAlert}
 */
proto.api.UserAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = new proto.api.Alert;
      reader.readMessage(value,proto.api.Alert.deserializeBinaryFromReader);
      msg.setAlert(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addDestinationIds(value);
      break;
    case 6:
      var value = /** @type {!proto.api.UserAlert.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setChainId(value);
      break;
    case 8:
      var value = /** @type {!proto.api.AlertSource} */ (reader.readEnum());
      msg.setAlertSource(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.UserAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.UserAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.UserAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.UserAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAlert();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.Alert.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDestinationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getChainId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAlertSource();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.UserAlert.Status = {
  ENABLED: 0,
  DISABLED: 1
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.UserAlert.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.api.UserAlert.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Alert alert = 3;
 * @return {?proto.api.Alert}
 */
proto.api.UserAlert.prototype.getAlert = function() {
  return /** @type{?proto.api.Alert} */ (
    jspb.Message.getWrapperField(this, proto.api.Alert, 3));
};


/**
 * @param {?proto.api.Alert|undefined} value
 * @return {!proto.api.UserAlert} returns this
*/
proto.api.UserAlert.prototype.setAlert = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.clearAlert = function() {
  return this.setAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.UserAlert.prototype.hasAlert = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.api.UserAlert.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string destination_ids = 5;
 * @return {!Array<string>}
 */
proto.api.UserAlert.prototype.getDestinationIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setDestinationIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.addDestinationIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.clearDestinationIdsList = function() {
  return this.setDestinationIdsList([]);
};


/**
 * optional Status status = 6;
 * @return {!proto.api.UserAlert.Status}
 */
proto.api.UserAlert.prototype.getStatus = function() {
  return /** @type {!proto.api.UserAlert.Status} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.api.UserAlert.Status} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string chain_id = 7;
 * @return {string}
 */
proto.api.UserAlert.prototype.getChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional AlertSource alert_source = 8;
 * @return {!proto.api.AlertSource}
 */
proto.api.UserAlert.prototype.getAlertSource = function() {
  return /** @type {!proto.api.AlertSource} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.api.AlertSource} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setAlertSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.api.UserAlert.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.UserAlert} returns this
 */
proto.api.UserAlert.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.Alert.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.Alert.ChainAlertCase = {
  CHAIN_ALERT_NOT_SET: 0,
  COSMOS_ALERT: 1,
  COSMOS_EVM_ALERT: 2,
  ETH_ALERT: 3
};

/**
 * @return {proto.api.Alert.ChainAlertCase}
 */
proto.api.Alert.prototype.getChainAlertCase = function() {
  return /** @type {proto.api.Alert.ChainAlertCase} */(jspb.Message.computeOneofCase(this, proto.api.Alert.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Alert.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Alert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Alert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Alert.toObject = function(includeInstance, msg) {
  var f, obj = {
    cosmosAlert: (f = msg.getCosmosAlert()) && proto.api.CosmosAlert.toObject(includeInstance, f),
    cosmosEvmAlert: (f = msg.getCosmosEvmAlert()) && proto.api.CosmosEvmAlert.toObject(includeInstance, f),
    ethAlert: (f = msg.getEthAlert()) && proto.api.EthAlert.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Alert}
 */
proto.api.Alert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Alert;
  return proto.api.Alert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Alert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Alert}
 */
proto.api.Alert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CosmosAlert;
      reader.readMessage(value,proto.api.CosmosAlert.deserializeBinaryFromReader);
      msg.setCosmosAlert(value);
      break;
    case 2:
      var value = new proto.api.CosmosEvmAlert;
      reader.readMessage(value,proto.api.CosmosEvmAlert.deserializeBinaryFromReader);
      msg.setCosmosEvmAlert(value);
      break;
    case 3:
      var value = new proto.api.EthAlert;
      reader.readMessage(value,proto.api.EthAlert.deserializeBinaryFromReader);
      msg.setEthAlert(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Alert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Alert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Alert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Alert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCosmosAlert();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.CosmosAlert.serializeBinaryToWriter
    );
  }
  f = message.getCosmosEvmAlert();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.CosmosEvmAlert.serializeBinaryToWriter
    );
  }
  f = message.getEthAlert();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.EthAlert.serializeBinaryToWriter
    );
  }
};


/**
 * optional CosmosAlert cosmos_alert = 1;
 * @return {?proto.api.CosmosAlert}
 */
proto.api.Alert.prototype.getCosmosAlert = function() {
  return /** @type{?proto.api.CosmosAlert} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosAlert, 1));
};


/**
 * @param {?proto.api.CosmosAlert|undefined} value
 * @return {!proto.api.Alert} returns this
*/
proto.api.Alert.prototype.setCosmosAlert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.Alert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Alert} returns this
 */
proto.api.Alert.prototype.clearCosmosAlert = function() {
  return this.setCosmosAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Alert.prototype.hasCosmosAlert = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CosmosEvmAlert cosmos_evm_alert = 2;
 * @return {?proto.api.CosmosEvmAlert}
 */
proto.api.Alert.prototype.getCosmosEvmAlert = function() {
  return /** @type{?proto.api.CosmosEvmAlert} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosEvmAlert, 2));
};


/**
 * @param {?proto.api.CosmosEvmAlert|undefined} value
 * @return {!proto.api.Alert} returns this
*/
proto.api.Alert.prototype.setCosmosEvmAlert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.Alert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Alert} returns this
 */
proto.api.Alert.prototype.clearCosmosEvmAlert = function() {
  return this.setCosmosEvmAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Alert.prototype.hasCosmosEvmAlert = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EthAlert eth_alert = 3;
 * @return {?proto.api.EthAlert}
 */
proto.api.Alert.prototype.getEthAlert = function() {
  return /** @type{?proto.api.EthAlert} */ (
    jspb.Message.getWrapperField(this, proto.api.EthAlert, 3));
};


/**
 * @param {?proto.api.EthAlert|undefined} value
 * @return {!proto.api.Alert} returns this
*/
proto.api.Alert.prototype.setEthAlert = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.api.Alert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.Alert} returns this
 */
proto.api.Alert.prototype.clearEthAlert = function() {
  return this.setEthAlert(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Alert.prototype.hasEthAlert = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.CosmosAlert.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.api.CosmosAlert.CosmosAlertCase = {
  COSMOS_ALERT_NOT_SET: 0,
  ALERT_COSMOS_SEND_FUNDS: 1,
  ALERT_COSMOS_MONITOR_FUNDS: 2,
  ALERT_COSMOS_SMART_CONTRACT_EVENTS: 3,
  ALERT_COSMOS_TX_OUTCOME: 4
};

/**
 * @return {proto.api.CosmosAlert.CosmosAlertCase}
 */
proto.api.CosmosAlert.prototype.getCosmosAlertCase = function() {
  return /** @type {proto.api.CosmosAlert.CosmosAlertCase} */(jspb.Message.computeOneofCase(this, proto.api.CosmosAlert.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CosmosAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CosmosAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CosmosAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertCosmosSendFunds: (f = msg.getAlertCosmosSendFunds()) && proto.api.AlertCosmosSendFunds.toObject(includeInstance, f),
    alertCosmosMonitorFunds: (f = msg.getAlertCosmosMonitorFunds()) && proto.api.AlertCosmosMonitorFunds.toObject(includeInstance, f),
    alertCosmosSmartContractEvents: (f = msg.getAlertCosmosSmartContractEvents()) && proto.api.AlertCosmosSmartContractEvents.toObject(includeInstance, f),
    alertCosmosTxOutcome: (f = msg.getAlertCosmosTxOutcome()) && proto.api.AlertCosmosTxOutcome.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CosmosAlert}
 */
proto.api.CosmosAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CosmosAlert;
  return proto.api.CosmosAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CosmosAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CosmosAlert}
 */
proto.api.CosmosAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AlertCosmosSendFunds;
      reader.readMessage(value,proto.api.AlertCosmosSendFunds.deserializeBinaryFromReader);
      msg.setAlertCosmosSendFunds(value);
      break;
    case 2:
      var value = new proto.api.AlertCosmosMonitorFunds;
      reader.readMessage(value,proto.api.AlertCosmosMonitorFunds.deserializeBinaryFromReader);
      msg.setAlertCosmosMonitorFunds(value);
      break;
    case 3:
      var value = new proto.api.AlertCosmosSmartContractEvents;
      reader.readMessage(value,proto.api.AlertCosmosSmartContractEvents.deserializeBinaryFromReader);
      msg.setAlertCosmosSmartContractEvents(value);
      break;
    case 4:
      var value = new proto.api.AlertCosmosTxOutcome;
      reader.readMessage(value,proto.api.AlertCosmosTxOutcome.deserializeBinaryFromReader);
      msg.setAlertCosmosTxOutcome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CosmosAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CosmosAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CosmosAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertCosmosSendFunds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.AlertCosmosSendFunds.serializeBinaryToWriter
    );
  }
  f = message.getAlertCosmosMonitorFunds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.AlertCosmosMonitorFunds.serializeBinaryToWriter
    );
  }
  f = message.getAlertCosmosSmartContractEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.AlertCosmosSmartContractEvents.serializeBinaryToWriter
    );
  }
  f = message.getAlertCosmosTxOutcome();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.AlertCosmosTxOutcome.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlertCosmosSendFunds alert_cosmos_send_funds = 1;
 * @return {?proto.api.AlertCosmosSendFunds}
 */
proto.api.CosmosAlert.prototype.getAlertCosmosSendFunds = function() {
  return /** @type{?proto.api.AlertCosmosSendFunds} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertCosmosSendFunds, 1));
};


/**
 * @param {?proto.api.AlertCosmosSendFunds|undefined} value
 * @return {!proto.api.CosmosAlert} returns this
*/
proto.api.CosmosAlert.prototype.setAlertCosmosSendFunds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.CosmosAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosAlert} returns this
 */
proto.api.CosmosAlert.prototype.clearAlertCosmosSendFunds = function() {
  return this.setAlertCosmosSendFunds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosAlert.prototype.hasAlertCosmosSendFunds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AlertCosmosMonitorFunds alert_cosmos_monitor_funds = 2;
 * @return {?proto.api.AlertCosmosMonitorFunds}
 */
proto.api.CosmosAlert.prototype.getAlertCosmosMonitorFunds = function() {
  return /** @type{?proto.api.AlertCosmosMonitorFunds} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertCosmosMonitorFunds, 2));
};


/**
 * @param {?proto.api.AlertCosmosMonitorFunds|undefined} value
 * @return {!proto.api.CosmosAlert} returns this
*/
proto.api.CosmosAlert.prototype.setAlertCosmosMonitorFunds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.CosmosAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosAlert} returns this
 */
proto.api.CosmosAlert.prototype.clearAlertCosmosMonitorFunds = function() {
  return this.setAlertCosmosMonitorFunds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosAlert.prototype.hasAlertCosmosMonitorFunds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AlertCosmosSmartContractEvents alert_cosmos_smart_contract_events = 3;
 * @return {?proto.api.AlertCosmosSmartContractEvents}
 */
proto.api.CosmosAlert.prototype.getAlertCosmosSmartContractEvents = function() {
  return /** @type{?proto.api.AlertCosmosSmartContractEvents} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertCosmosSmartContractEvents, 3));
};


/**
 * @param {?proto.api.AlertCosmosSmartContractEvents|undefined} value
 * @return {!proto.api.CosmosAlert} returns this
*/
proto.api.CosmosAlert.prototype.setAlertCosmosSmartContractEvents = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.api.CosmosAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosAlert} returns this
 */
proto.api.CosmosAlert.prototype.clearAlertCosmosSmartContractEvents = function() {
  return this.setAlertCosmosSmartContractEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosAlert.prototype.hasAlertCosmosSmartContractEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AlertCosmosTxOutcome alert_cosmos_tx_outcome = 4;
 * @return {?proto.api.AlertCosmosTxOutcome}
 */
proto.api.CosmosAlert.prototype.getAlertCosmosTxOutcome = function() {
  return /** @type{?proto.api.AlertCosmosTxOutcome} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertCosmosTxOutcome, 4));
};


/**
 * @param {?proto.api.AlertCosmosTxOutcome|undefined} value
 * @return {!proto.api.CosmosAlert} returns this
*/
proto.api.CosmosAlert.prototype.setAlertCosmosTxOutcome = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.api.CosmosAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosAlert} returns this
 */
proto.api.CosmosAlert.prototype.clearAlertCosmosTxOutcome = function() {
  return this.setAlertCosmosTxOutcome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosAlert.prototype.hasAlertCosmosTxOutcome = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.CosmosEvmAlert.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.api.CosmosEvmAlert.CosmosEvmAlertCase = {
  COSMOS_EVM_ALERT_NOT_SET: 0,
  ALERT_ETH_MONITOR_FUNDS: 1,
  ALERT_ETH_TX_OUTCOME: 2,
  ALERT_ETH_SMART_CONTRACT_EVENTS: 3,
  ALERT_COSMOS_MONITOR_FUNDS: 4,
  ALERT_COSMOS_TX_OUTCOME: 5
};

/**
 * @return {proto.api.CosmosEvmAlert.CosmosEvmAlertCase}
 */
proto.api.CosmosEvmAlert.prototype.getCosmosEvmAlertCase = function() {
  return /** @type {proto.api.CosmosEvmAlert.CosmosEvmAlertCase} */(jspb.Message.computeOneofCase(this, proto.api.CosmosEvmAlert.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CosmosEvmAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CosmosEvmAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CosmosEvmAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosEvmAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertEthMonitorFunds: (f = msg.getAlertEthMonitorFunds()) && proto.api.AlertEthMonitorFunds.toObject(includeInstance, f),
    alertEthTxOutcome: (f = msg.getAlertEthTxOutcome()) && proto.api.AlertEthTxOutcome.toObject(includeInstance, f),
    alertEthSmartContractEvents: (f = msg.getAlertEthSmartContractEvents()) && proto.api.AlertEthSmartContractEvents.toObject(includeInstance, f),
    alertCosmosMonitorFunds: (f = msg.getAlertCosmosMonitorFunds()) && proto.api.AlertCosmosMonitorFunds.toObject(includeInstance, f),
    alertCosmosTxOutcome: (f = msg.getAlertCosmosTxOutcome()) && proto.api.AlertCosmosTxOutcome.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CosmosEvmAlert}
 */
proto.api.CosmosEvmAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CosmosEvmAlert;
  return proto.api.CosmosEvmAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CosmosEvmAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CosmosEvmAlert}
 */
proto.api.CosmosEvmAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AlertEthMonitorFunds;
      reader.readMessage(value,proto.api.AlertEthMonitorFunds.deserializeBinaryFromReader);
      msg.setAlertEthMonitorFunds(value);
      break;
    case 2:
      var value = new proto.api.AlertEthTxOutcome;
      reader.readMessage(value,proto.api.AlertEthTxOutcome.deserializeBinaryFromReader);
      msg.setAlertEthTxOutcome(value);
      break;
    case 3:
      var value = new proto.api.AlertEthSmartContractEvents;
      reader.readMessage(value,proto.api.AlertEthSmartContractEvents.deserializeBinaryFromReader);
      msg.setAlertEthSmartContractEvents(value);
      break;
    case 4:
      var value = new proto.api.AlertCosmosMonitorFunds;
      reader.readMessage(value,proto.api.AlertCosmosMonitorFunds.deserializeBinaryFromReader);
      msg.setAlertCosmosMonitorFunds(value);
      break;
    case 5:
      var value = new proto.api.AlertCosmosTxOutcome;
      reader.readMessage(value,proto.api.AlertCosmosTxOutcome.deserializeBinaryFromReader);
      msg.setAlertCosmosTxOutcome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CosmosEvmAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CosmosEvmAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CosmosEvmAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosEvmAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertEthMonitorFunds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.AlertEthMonitorFunds.serializeBinaryToWriter
    );
  }
  f = message.getAlertEthTxOutcome();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.AlertEthTxOutcome.serializeBinaryToWriter
    );
  }
  f = message.getAlertEthSmartContractEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.AlertEthSmartContractEvents.serializeBinaryToWriter
    );
  }
  f = message.getAlertCosmosMonitorFunds();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.AlertCosmosMonitorFunds.serializeBinaryToWriter
    );
  }
  f = message.getAlertCosmosTxOutcome();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.api.AlertCosmosTxOutcome.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlertEthMonitorFunds alert_eth_monitor_funds = 1;
 * @return {?proto.api.AlertEthMonitorFunds}
 */
proto.api.CosmosEvmAlert.prototype.getAlertEthMonitorFunds = function() {
  return /** @type{?proto.api.AlertEthMonitorFunds} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertEthMonitorFunds, 1));
};


/**
 * @param {?proto.api.AlertEthMonitorFunds|undefined} value
 * @return {!proto.api.CosmosEvmAlert} returns this
*/
proto.api.CosmosEvmAlert.prototype.setAlertEthMonitorFunds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.CosmosEvmAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmAlert} returns this
 */
proto.api.CosmosEvmAlert.prototype.clearAlertEthMonitorFunds = function() {
  return this.setAlertEthMonitorFunds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmAlert.prototype.hasAlertEthMonitorFunds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AlertEthTxOutcome alert_eth_tx_outcome = 2;
 * @return {?proto.api.AlertEthTxOutcome}
 */
proto.api.CosmosEvmAlert.prototype.getAlertEthTxOutcome = function() {
  return /** @type{?proto.api.AlertEthTxOutcome} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertEthTxOutcome, 2));
};


/**
 * @param {?proto.api.AlertEthTxOutcome|undefined} value
 * @return {!proto.api.CosmosEvmAlert} returns this
*/
proto.api.CosmosEvmAlert.prototype.setAlertEthTxOutcome = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.CosmosEvmAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmAlert} returns this
 */
proto.api.CosmosEvmAlert.prototype.clearAlertEthTxOutcome = function() {
  return this.setAlertEthTxOutcome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmAlert.prototype.hasAlertEthTxOutcome = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AlertEthSmartContractEvents alert_eth_smart_contract_events = 3;
 * @return {?proto.api.AlertEthSmartContractEvents}
 */
proto.api.CosmosEvmAlert.prototype.getAlertEthSmartContractEvents = function() {
  return /** @type{?proto.api.AlertEthSmartContractEvents} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertEthSmartContractEvents, 3));
};


/**
 * @param {?proto.api.AlertEthSmartContractEvents|undefined} value
 * @return {!proto.api.CosmosEvmAlert} returns this
*/
proto.api.CosmosEvmAlert.prototype.setAlertEthSmartContractEvents = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.api.CosmosEvmAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmAlert} returns this
 */
proto.api.CosmosEvmAlert.prototype.clearAlertEthSmartContractEvents = function() {
  return this.setAlertEthSmartContractEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmAlert.prototype.hasAlertEthSmartContractEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional AlertCosmosMonitorFunds alert_cosmos_monitor_funds = 4;
 * @return {?proto.api.AlertCosmosMonitorFunds}
 */
proto.api.CosmosEvmAlert.prototype.getAlertCosmosMonitorFunds = function() {
  return /** @type{?proto.api.AlertCosmosMonitorFunds} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertCosmosMonitorFunds, 4));
};


/**
 * @param {?proto.api.AlertCosmosMonitorFunds|undefined} value
 * @return {!proto.api.CosmosEvmAlert} returns this
*/
proto.api.CosmosEvmAlert.prototype.setAlertCosmosMonitorFunds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.api.CosmosEvmAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmAlert} returns this
 */
proto.api.CosmosEvmAlert.prototype.clearAlertCosmosMonitorFunds = function() {
  return this.setAlertCosmosMonitorFunds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmAlert.prototype.hasAlertCosmosMonitorFunds = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AlertCosmosTxOutcome alert_cosmos_tx_outcome = 5;
 * @return {?proto.api.AlertCosmosTxOutcome}
 */
proto.api.CosmosEvmAlert.prototype.getAlertCosmosTxOutcome = function() {
  return /** @type{?proto.api.AlertCosmosTxOutcome} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertCosmosTxOutcome, 5));
};


/**
 * @param {?proto.api.AlertCosmosTxOutcome|undefined} value
 * @return {!proto.api.CosmosEvmAlert} returns this
*/
proto.api.CosmosEvmAlert.prototype.setAlertCosmosTxOutcome = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.api.CosmosEvmAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmAlert} returns this
 */
proto.api.CosmosEvmAlert.prototype.clearAlertCosmosTxOutcome = function() {
  return this.setAlertCosmosTxOutcome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmAlert.prototype.hasAlertCosmosTxOutcome = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.EthAlert.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.EthAlert.EthAlertCase = {
  ETH_ALERT_NOT_SET: 0,
  ALERT_ETH_MONITOR_FUNDS: 1,
  ALERT_ETH_TX_OUTCOME: 2,
  ALERT_ETH_SMART_CONTRACT_EVENTS: 3
};

/**
 * @return {proto.api.EthAlert.EthAlertCase}
 */
proto.api.EthAlert.prototype.getEthAlertCase = function() {
  return /** @type {proto.api.EthAlert.EthAlertCase} */(jspb.Message.computeOneofCase(this, proto.api.EthAlert.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.EthAlert.prototype.toObject = function(opt_includeInstance) {
  return proto.api.EthAlert.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.EthAlert} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.EthAlert.toObject = function(includeInstance, msg) {
  var f, obj = {
    alertEthMonitorFunds: (f = msg.getAlertEthMonitorFunds()) && proto.api.AlertEthMonitorFunds.toObject(includeInstance, f),
    alertEthTxOutcome: (f = msg.getAlertEthTxOutcome()) && proto.api.AlertEthTxOutcome.toObject(includeInstance, f),
    alertEthSmartContractEvents: (f = msg.getAlertEthSmartContractEvents()) && proto.api.AlertEthSmartContractEvents.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.EthAlert}
 */
proto.api.EthAlert.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.EthAlert;
  return proto.api.EthAlert.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.EthAlert} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.EthAlert}
 */
proto.api.EthAlert.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.AlertEthMonitorFunds;
      reader.readMessage(value,proto.api.AlertEthMonitorFunds.deserializeBinaryFromReader);
      msg.setAlertEthMonitorFunds(value);
      break;
    case 2:
      var value = new proto.api.AlertEthTxOutcome;
      reader.readMessage(value,proto.api.AlertEthTxOutcome.deserializeBinaryFromReader);
      msg.setAlertEthTxOutcome(value);
      break;
    case 3:
      var value = new proto.api.AlertEthSmartContractEvents;
      reader.readMessage(value,proto.api.AlertEthSmartContractEvents.deserializeBinaryFromReader);
      msg.setAlertEthSmartContractEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.EthAlert.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.EthAlert.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.EthAlert} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.EthAlert.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlertEthMonitorFunds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.AlertEthMonitorFunds.serializeBinaryToWriter
    );
  }
  f = message.getAlertEthTxOutcome();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.AlertEthTxOutcome.serializeBinaryToWriter
    );
  }
  f = message.getAlertEthSmartContractEvents();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.AlertEthSmartContractEvents.serializeBinaryToWriter
    );
  }
};


/**
 * optional AlertEthMonitorFunds alert_eth_monitor_funds = 1;
 * @return {?proto.api.AlertEthMonitorFunds}
 */
proto.api.EthAlert.prototype.getAlertEthMonitorFunds = function() {
  return /** @type{?proto.api.AlertEthMonitorFunds} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertEthMonitorFunds, 1));
};


/**
 * @param {?proto.api.AlertEthMonitorFunds|undefined} value
 * @return {!proto.api.EthAlert} returns this
*/
proto.api.EthAlert.prototype.setAlertEthMonitorFunds = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.EthAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.EthAlert} returns this
 */
proto.api.EthAlert.prototype.clearAlertEthMonitorFunds = function() {
  return this.setAlertEthMonitorFunds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.EthAlert.prototype.hasAlertEthMonitorFunds = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AlertEthTxOutcome alert_eth_tx_outcome = 2;
 * @return {?proto.api.AlertEthTxOutcome}
 */
proto.api.EthAlert.prototype.getAlertEthTxOutcome = function() {
  return /** @type{?proto.api.AlertEthTxOutcome} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertEthTxOutcome, 2));
};


/**
 * @param {?proto.api.AlertEthTxOutcome|undefined} value
 * @return {!proto.api.EthAlert} returns this
*/
proto.api.EthAlert.prototype.setAlertEthTxOutcome = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.EthAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.EthAlert} returns this
 */
proto.api.EthAlert.prototype.clearAlertEthTxOutcome = function() {
  return this.setAlertEthTxOutcome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.EthAlert.prototype.hasAlertEthTxOutcome = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AlertEthSmartContractEvents alert_eth_smart_contract_events = 3;
 * @return {?proto.api.AlertEthSmartContractEvents}
 */
proto.api.EthAlert.prototype.getAlertEthSmartContractEvents = function() {
  return /** @type{?proto.api.AlertEthSmartContractEvents} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertEthSmartContractEvents, 3));
};


/**
 * @param {?proto.api.AlertEthSmartContractEvents|undefined} value
 * @return {!proto.api.EthAlert} returns this
*/
proto.api.EthAlert.prototype.setAlertEthSmartContractEvents = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.api.EthAlert.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.EthAlert} returns this
 */
proto.api.EthAlert.prototype.clearAlertEthSmartContractEvents = function() {
  return this.setAlertEthSmartContractEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.EthAlert.prototype.hasAlertEthSmartContractEvents = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertEthSmartContractEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertEthSmartContractEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertEthSmartContractEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertEthSmartContractEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractAddr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contractAbi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    eventAttributesMap: (f = msg.getEventAttributesMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertEthSmartContractEvents}
 */
proto.api.AlertEthSmartContractEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertEthSmartContractEvents;
  return proto.api.AlertEthSmartContractEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertEthSmartContractEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertEthSmartContractEvents}
 */
proto.api.AlertEthSmartContractEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAbi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 4:
      var value = msg.getEventAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertEthSmartContractEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertEthSmartContractEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertEthSmartContractEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertEthSmartContractEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractAddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContractAbi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEventAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional string contract_addr = 1;
 * @return {string}
 */
proto.api.AlertEthSmartContractEvents.prototype.getContractAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertEthSmartContractEvents} returns this
 */
proto.api.AlertEthSmartContractEvents.prototype.setContractAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contract_abi = 2;
 * @return {string}
 */
proto.api.AlertEthSmartContractEvents.prototype.getContractAbi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertEthSmartContractEvents} returns this
 */
proto.api.AlertEthSmartContractEvents.prototype.setContractAbi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_name = 3;
 * @return {string}
 */
proto.api.AlertEthSmartContractEvents.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertEthSmartContractEvents} returns this
 */
proto.api.AlertEthSmartContractEvents.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * map<string, string> event_attributes = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.AlertEthSmartContractEvents.prototype.getEventAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.AlertEthSmartContractEvents} returns this
 */
proto.api.AlertEthSmartContractEvents.prototype.clearEventAttributesMap = function() {
  this.getEventAttributesMap().clear();
  return this;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertEthMonitorFunds.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertEthMonitorFunds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertEthMonitorFunds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertEthMonitorFunds.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertEthMonitorFunds}
 */
proto.api.AlertEthMonitorFunds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertEthMonitorFunds;
  return proto.api.AlertEthMonitorFunds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertEthMonitorFunds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertEthMonitorFunds}
 */
proto.api.AlertEthMonitorFunds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertEthMonitorFunds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertEthMonitorFunds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertEthMonitorFunds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertEthMonitorFunds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.api.AlertEthMonitorFunds.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertEthMonitorFunds} returns this
 */
proto.api.AlertEthMonitorFunds.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertEthTxOutcome.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertEthTxOutcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertEthTxOutcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertEthTxOutcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    signer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outcome: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertEthTxOutcome}
 */
proto.api.AlertEthTxOutcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertEthTxOutcome;
  return proto.api.AlertEthTxOutcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertEthTxOutcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertEthTxOutcome}
 */
proto.api.AlertEthTxOutcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSigner(value);
      break;
    case 2:
      var value = /** @type {!proto.api.AlertEthTxOutcome.EthTxOutcome} */ (reader.readEnum());
      msg.setOutcome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertEthTxOutcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertEthTxOutcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertEthTxOutcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertEthTxOutcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSigner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutcome();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.AlertEthTxOutcome.EthTxOutcome = {
  SUCCEEDED: 0,
  FAILED: 1
};

/**
 * optional string signer = 1;
 * @return {string}
 */
proto.api.AlertEthTxOutcome.prototype.getSigner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertEthTxOutcome} returns this
 */
proto.api.AlertEthTxOutcome.prototype.setSigner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EthTxOutcome outcome = 2;
 * @return {!proto.api.AlertEthTxOutcome.EthTxOutcome}
 */
proto.api.AlertEthTxOutcome.prototype.getOutcome = function() {
  return /** @type {!proto.api.AlertEthTxOutcome.EthTxOutcome} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.AlertEthTxOutcome.EthTxOutcome} value
 * @return {!proto.api.AlertEthTxOutcome} returns this
 */
proto.api.AlertEthTxOutcome.prototype.setOutcome = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertCosmosSmartContractEvents.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertCosmosSmartContractEvents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertCosmosSmartContractEvents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosSmartContractEvents.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventAttributesMap: (f = msg.getEventAttributesMap()) ? f.toObject(includeInstance, undefined) : [],
    eventName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertCosmosSmartContractEvents}
 */
proto.api.AlertCosmosSmartContractEvents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertCosmosSmartContractEvents;
  return proto.api.AlertCosmosSmartContractEvents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertCosmosSmartContractEvents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertCosmosSmartContractEvents}
 */
proto.api.AlertCosmosSmartContractEvents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = msg.getEventAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertCosmosSmartContractEvents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertCosmosSmartContractEvents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertCosmosSmartContractEvents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosSmartContractEvents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.api.AlertCosmosSmartContractEvents.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertCosmosSmartContractEvents} returns this
 */
proto.api.AlertCosmosSmartContractEvents.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, string> event_attributes = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.AlertCosmosSmartContractEvents.prototype.getEventAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.AlertCosmosSmartContractEvents} returns this
 */
proto.api.AlertCosmosSmartContractEvents.prototype.clearEventAttributesMap = function() {
  this.getEventAttributesMap().clear();
  return this;
};


/**
 * optional string event_name = 3;
 * @return {string}
 */
proto.api.AlertCosmosSmartContractEvents.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertCosmosSmartContractEvents} returns this
 */
proto.api.AlertCosmosSmartContractEvents.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertCosmosMonitorFunds.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertCosmosMonitorFunds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertCosmosMonitorFunds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosMonitorFunds.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertCosmosMonitorFunds}
 */
proto.api.AlertCosmosMonitorFunds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertCosmosMonitorFunds;
  return proto.api.AlertCosmosMonitorFunds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertCosmosMonitorFunds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertCosmosMonitorFunds}
 */
proto.api.AlertCosmosMonitorFunds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertCosmosMonitorFunds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertCosmosMonitorFunds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertCosmosMonitorFunds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosMonitorFunds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.api.AlertCosmosMonitorFunds.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertCosmosMonitorFunds} returns this
 */
proto.api.AlertCosmosMonitorFunds.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertCosmosSendFunds.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertCosmosSendFunds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertCosmosSendFunds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosSendFunds.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertCosmosSendFunds}
 */
proto.api.AlertCosmosSendFunds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertCosmosSendFunds;
  return proto.api.AlertCosmosSendFunds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertCosmosSendFunds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertCosmosSendFunds}
 */
proto.api.AlertCosmosSendFunds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertCosmosSendFunds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertCosmosSendFunds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertCosmosSendFunds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosSendFunds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.api.AlertCosmosSendFunds.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertCosmosSendFunds} returns this
 */
proto.api.AlertCosmosSendFunds.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.api.AlertCosmosSendFunds.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertCosmosSendFunds} returns this
 */
proto.api.AlertCosmosSendFunds.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertCosmosTxOutcome.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertCosmosTxOutcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertCosmosTxOutcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosTxOutcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    signer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outcome: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertCosmosTxOutcome}
 */
proto.api.AlertCosmosTxOutcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertCosmosTxOutcome;
  return proto.api.AlertCosmosTxOutcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertCosmosTxOutcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertCosmosTxOutcome}
 */
proto.api.AlertCosmosTxOutcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSigner(value);
      break;
    case 2:
      var value = /** @type {!proto.api.AlertCosmosTxOutcome.CosmosTxOutcome} */ (reader.readEnum());
      msg.setOutcome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertCosmosTxOutcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertCosmosTxOutcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertCosmosTxOutcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertCosmosTxOutcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSigner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutcome();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.api.AlertCosmosTxOutcome.CosmosTxOutcome = {
  SUCCEEDED: 0,
  FAILED: 1
};

/**
 * optional string signer = 1;
 * @return {string}
 */
proto.api.AlertCosmosTxOutcome.prototype.getSigner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertCosmosTxOutcome} returns this
 */
proto.api.AlertCosmosTxOutcome.prototype.setSigner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CosmosTxOutcome outcome = 2;
 * @return {!proto.api.AlertCosmosTxOutcome.CosmosTxOutcome}
 */
proto.api.AlertCosmosTxOutcome.prototype.getOutcome = function() {
  return /** @type {!proto.api.AlertCosmosTxOutcome.CosmosTxOutcome} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.api.AlertCosmosTxOutcome.CosmosTxOutcome} value
 * @return {!proto.api.AlertCosmosTxOutcome} returns this
 */
proto.api.AlertCosmosTxOutcome.prototype.setOutcome = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.Destination.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.api.Destination.DestinationCase = {
  DESTINATION_NOT_SET: 0,
  EMAIL: 1,
  WEBHOOK: 2,
  TELEGRAM: 3,
  DISCORD_WEBHOOK: 4
};

/**
 * @return {proto.api.Destination.DestinationCase}
 */
proto.api.Destination.prototype.getDestinationCase = function() {
  return /** @type {proto.api.Destination.DestinationCase} */(jspb.Message.computeOneofCase(this, proto.api.Destination.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.Destination.prototype.toObject = function(opt_includeInstance) {
  return proto.api.Destination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.Destination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Destination.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    webhook: jspb.Message.getFieldWithDefault(msg, 2, ""),
    telegram: jspb.Message.getFieldWithDefault(msg, 3, ""),
    discordWebhook: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.Destination}
 */
proto.api.Destination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.Destination;
  return proto.api.Destination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.Destination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.Destination}
 */
proto.api.Destination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebhook(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTelegram(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscordWebhook(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.Destination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.Destination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.Destination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.Destination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.api.Destination.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.setEmail = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.api.Destination.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.clearEmail = function() {
  return jspb.Message.setOneofField(this, 1, proto.api.Destination.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Destination.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string webhook = 2;
 * @return {string}
 */
proto.api.Destination.prototype.getWebhook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.setWebhook = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.api.Destination.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.clearWebhook = function() {
  return jspb.Message.setOneofField(this, 2, proto.api.Destination.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Destination.prototype.hasWebhook = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string telegram = 3;
 * @return {string}
 */
proto.api.Destination.prototype.getTelegram = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.setTelegram = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.api.Destination.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.clearTelegram = function() {
  return jspb.Message.setOneofField(this, 3, proto.api.Destination.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Destination.prototype.hasTelegram = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string discord_webhook = 4;
 * @return {string}
 */
proto.api.Destination.prototype.getDiscordWebhook = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.setDiscordWebhook = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.api.Destination.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.api.Destination} returns this
 */
proto.api.Destination.prototype.clearDiscordWebhook = function() {
  return jspb.Message.setOneofField(this, 4, proto.api.Destination.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.Destination.prototype.hasDiscordWebhook = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertNotification.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertNotification.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertNotification} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertNotification.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    notificationData: (f = msg.getNotificationData()) && proto.api.AlertNotificationData.toObject(includeInstance, f),
    alertId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    alertSourceId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertNotification}
 */
proto.api.AlertNotification.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertNotification;
  return proto.api.AlertNotification.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertNotification} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertNotification}
 */
proto.api.AlertNotification.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.api.AlertNotificationData;
      reader.readMessage(value,proto.api.AlertNotificationData.deserializeBinaryFromReader);
      msg.setNotificationData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlertSourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertNotification.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertNotification.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertNotification} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertNotification.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNotificationData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.AlertNotificationData.serializeBinaryToWriter
    );
  }
  f = message.getAlertId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAlertSourceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.api.AlertNotification.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertNotification} returns this
 */
proto.api.AlertNotification.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional AlertNotificationData notification_data = 2;
 * @return {?proto.api.AlertNotificationData}
 */
proto.api.AlertNotification.prototype.getNotificationData = function() {
  return /** @type{?proto.api.AlertNotificationData} */ (
    jspb.Message.getWrapperField(this, proto.api.AlertNotificationData, 2));
};


/**
 * @param {?proto.api.AlertNotificationData|undefined} value
 * @return {!proto.api.AlertNotification} returns this
*/
proto.api.AlertNotification.prototype.setNotificationData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AlertNotification} returns this
 */
proto.api.AlertNotification.prototype.clearNotificationData = function() {
  return this.setNotificationData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AlertNotification.prototype.hasNotificationData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string alert_id = 3;
 * @return {string}
 */
proto.api.AlertNotification.prototype.getAlertId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertNotification} returns this
 */
proto.api.AlertNotification.prototype.setAlertId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string alert_source_id = 4;
 * @return {string}
 */
proto.api.AlertNotification.prototype.getAlertSourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.AlertNotification} returns this
 */
proto.api.AlertNotification.prototype.setAlertSourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.AlertNotificationData.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.api.AlertNotificationData.AlertNotificationDataCase = {
  ALERT_NOTIFICATION_DATA_NOT_SET: 0,
  MONITOR_FUNDS_COIN: 1,
  MONITOR_FUNDS_TOKEN: 2,
  TX_OUTCOME: 3,
  SC_EVENTS: 4
};

/**
 * @return {proto.api.AlertNotificationData.AlertNotificationDataCase}
 */
proto.api.AlertNotificationData.prototype.getAlertNotificationDataCase = function() {
  return /** @type {proto.api.AlertNotificationData.AlertNotificationDataCase} */(jspb.Message.computeOneofCase(this, proto.api.AlertNotificationData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.AlertNotificationData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.AlertNotificationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.AlertNotificationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertNotificationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    monitorFundsCoin: (f = msg.getMonitorFundsCoin()) && proto.api.MonitorFundsCoinNotificationData.toObject(includeInstance, f),
    monitorFundsToken: (f = msg.getMonitorFundsToken()) && proto.api.MonitorFundsTokenNotificationData.toObject(includeInstance, f),
    txOutcome: (f = msg.getTxOutcome()) && proto.api.TxOutcomeNotificationData.toObject(includeInstance, f),
    scEvents: (f = msg.getScEvents()) && proto.api.SmartContractEventsNotificationData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.AlertNotificationData}
 */
proto.api.AlertNotificationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.AlertNotificationData;
  return proto.api.AlertNotificationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.AlertNotificationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.AlertNotificationData}
 */
proto.api.AlertNotificationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.MonitorFundsCoinNotificationData;
      reader.readMessage(value,proto.api.MonitorFundsCoinNotificationData.deserializeBinaryFromReader);
      msg.setMonitorFundsCoin(value);
      break;
    case 2:
      var value = new proto.api.MonitorFundsTokenNotificationData;
      reader.readMessage(value,proto.api.MonitorFundsTokenNotificationData.deserializeBinaryFromReader);
      msg.setMonitorFundsToken(value);
      break;
    case 3:
      var value = new proto.api.TxOutcomeNotificationData;
      reader.readMessage(value,proto.api.TxOutcomeNotificationData.deserializeBinaryFromReader);
      msg.setTxOutcome(value);
      break;
    case 4:
      var value = new proto.api.SmartContractEventsNotificationData;
      reader.readMessage(value,proto.api.SmartContractEventsNotificationData.deserializeBinaryFromReader);
      msg.setScEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.AlertNotificationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.AlertNotificationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.AlertNotificationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.AlertNotificationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonitorFundsCoin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.MonitorFundsCoinNotificationData.serializeBinaryToWriter
    );
  }
  f = message.getMonitorFundsToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.MonitorFundsTokenNotificationData.serializeBinaryToWriter
    );
  }
  f = message.getTxOutcome();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.TxOutcomeNotificationData.serializeBinaryToWriter
    );
  }
  f = message.getScEvents();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.api.SmartContractEventsNotificationData.serializeBinaryToWriter
    );
  }
};


/**
 * optional MonitorFundsCoinNotificationData monitor_funds_coin = 1;
 * @return {?proto.api.MonitorFundsCoinNotificationData}
 */
proto.api.AlertNotificationData.prototype.getMonitorFundsCoin = function() {
  return /** @type{?proto.api.MonitorFundsCoinNotificationData} */ (
    jspb.Message.getWrapperField(this, proto.api.MonitorFundsCoinNotificationData, 1));
};


/**
 * @param {?proto.api.MonitorFundsCoinNotificationData|undefined} value
 * @return {!proto.api.AlertNotificationData} returns this
*/
proto.api.AlertNotificationData.prototype.setMonitorFundsCoin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.AlertNotificationData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AlertNotificationData} returns this
 */
proto.api.AlertNotificationData.prototype.clearMonitorFundsCoin = function() {
  return this.setMonitorFundsCoin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AlertNotificationData.prototype.hasMonitorFundsCoin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MonitorFundsTokenNotificationData monitor_funds_token = 2;
 * @return {?proto.api.MonitorFundsTokenNotificationData}
 */
proto.api.AlertNotificationData.prototype.getMonitorFundsToken = function() {
  return /** @type{?proto.api.MonitorFundsTokenNotificationData} */ (
    jspb.Message.getWrapperField(this, proto.api.MonitorFundsTokenNotificationData, 2));
};


/**
 * @param {?proto.api.MonitorFundsTokenNotificationData|undefined} value
 * @return {!proto.api.AlertNotificationData} returns this
*/
proto.api.AlertNotificationData.prototype.setMonitorFundsToken = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.AlertNotificationData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AlertNotificationData} returns this
 */
proto.api.AlertNotificationData.prototype.clearMonitorFundsToken = function() {
  return this.setMonitorFundsToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AlertNotificationData.prototype.hasMonitorFundsToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TxOutcomeNotificationData tx_outcome = 3;
 * @return {?proto.api.TxOutcomeNotificationData}
 */
proto.api.AlertNotificationData.prototype.getTxOutcome = function() {
  return /** @type{?proto.api.TxOutcomeNotificationData} */ (
    jspb.Message.getWrapperField(this, proto.api.TxOutcomeNotificationData, 3));
};


/**
 * @param {?proto.api.TxOutcomeNotificationData|undefined} value
 * @return {!proto.api.AlertNotificationData} returns this
*/
proto.api.AlertNotificationData.prototype.setTxOutcome = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.api.AlertNotificationData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AlertNotificationData} returns this
 */
proto.api.AlertNotificationData.prototype.clearTxOutcome = function() {
  return this.setTxOutcome(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AlertNotificationData.prototype.hasTxOutcome = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SmartContractEventsNotificationData sc_events = 4;
 * @return {?proto.api.SmartContractEventsNotificationData}
 */
proto.api.AlertNotificationData.prototype.getScEvents = function() {
  return /** @type{?proto.api.SmartContractEventsNotificationData} */ (
    jspb.Message.getWrapperField(this, proto.api.SmartContractEventsNotificationData, 4));
};


/**
 * @param {?proto.api.SmartContractEventsNotificationData|undefined} value
 * @return {!proto.api.AlertNotificationData} returns this
*/
proto.api.AlertNotificationData.prototype.setScEvents = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.api.AlertNotificationData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.AlertNotificationData} returns this
 */
proto.api.AlertNotificationData.prototype.clearScEvents = function() {
  return this.setScEvents(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.AlertNotificationData.prototype.hasScEvents = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.SmartContractEventsNotificationData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.SmartContractEventsNotificationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.SmartContractEventsNotificationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SmartContractEventsNotificationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    contractAddr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventAttributesMap: (f = msg.getEventAttributesMap()) ? f.toObject(includeInstance, undefined) : [],
    txHash: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.SmartContractEventsNotificationData}
 */
proto.api.SmartContractEventsNotificationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.SmartContractEventsNotificationData;
  return proto.api.SmartContractEventsNotificationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.SmartContractEventsNotificationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.SmartContractEventsNotificationData}
 */
proto.api.SmartContractEventsNotificationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 3:
      var value = msg.getEventAttributesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.SmartContractEventsNotificationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.SmartContractEventsNotificationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.SmartContractEventsNotificationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.SmartContractEventsNotificationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContractAddr();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventAttributesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getTxHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string contract_addr = 1;
 * @return {string}
 */
proto.api.SmartContractEventsNotificationData.prototype.getContractAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SmartContractEventsNotificationData} returns this
 */
proto.api.SmartContractEventsNotificationData.prototype.setContractAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_name = 2;
 * @return {string}
 */
proto.api.SmartContractEventsNotificationData.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SmartContractEventsNotificationData} returns this
 */
proto.api.SmartContractEventsNotificationData.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * map<string, string> event_attributes = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.api.SmartContractEventsNotificationData.prototype.getEventAttributesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.api.SmartContractEventsNotificationData} returns this
 */
proto.api.SmartContractEventsNotificationData.prototype.clearEventAttributesMap = function() {
  this.getEventAttributesMap().clear();
  return this;
};


/**
 * optional string tx_hash = 4;
 * @return {string}
 */
proto.api.SmartContractEventsNotificationData.prototype.getTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.SmartContractEventsNotificationData} returns this
 */
proto.api.SmartContractEventsNotificationData.prototype.setTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.TxOutcomeNotificationData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.TxOutcomeNotificationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.TxOutcomeNotificationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TxOutcomeNotificationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    signer: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outcome: jspb.Message.getFieldWithDefault(msg, 2, ""),
    txHash: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.TxOutcomeNotificationData}
 */
proto.api.TxOutcomeNotificationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.TxOutcomeNotificationData;
  return proto.api.TxOutcomeNotificationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.TxOutcomeNotificationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.TxOutcomeNotificationData}
 */
proto.api.TxOutcomeNotificationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSigner(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcome(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.TxOutcomeNotificationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.TxOutcomeNotificationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.TxOutcomeNotificationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.TxOutcomeNotificationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSigner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutcome();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTxHash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string signer = 1;
 * @return {string}
 */
proto.api.TxOutcomeNotificationData.prototype.getSigner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TxOutcomeNotificationData} returns this
 */
proto.api.TxOutcomeNotificationData.prototype.setSigner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string outcome = 2;
 * @return {string}
 */
proto.api.TxOutcomeNotificationData.prototype.getOutcome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TxOutcomeNotificationData} returns this
 */
proto.api.TxOutcomeNotificationData.prototype.setOutcome = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tx_hash = 3;
 * @return {string}
 */
proto.api.TxOutcomeNotificationData.prototype.getTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.TxOutcomeNotificationData} returns this
 */
proto.api.TxOutcomeNotificationData.prototype.setTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.api.MonitorFundsCoinNotificationData.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MonitorFundsCoinNotificationData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MonitorFundsCoinNotificationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MonitorFundsCoinNotificationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MonitorFundsCoinNotificationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, ""),
    amountList: jspb.Message.toObjectList(msg.getAmountList(),
    proto.api.MonitorFundsCoinNotificationData.CoinAmount.toObject, includeInstance),
    txHash: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MonitorFundsCoinNotificationData}
 */
proto.api.MonitorFundsCoinNotificationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MonitorFundsCoinNotificationData;
  return proto.api.MonitorFundsCoinNotificationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MonitorFundsCoinNotificationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MonitorFundsCoinNotificationData}
 */
proto.api.MonitorFundsCoinNotificationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    case 3:
      var value = new proto.api.MonitorFundsCoinNotificationData.CoinAmount;
      reader.readMessage(value,proto.api.MonitorFundsCoinNotificationData.CoinAmount.deserializeBinaryFromReader);
      msg.addAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MonitorFundsCoinNotificationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MonitorFundsCoinNotificationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MonitorFundsCoinNotificationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MonitorFundsCoinNotificationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAmountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.api.MonitorFundsCoinNotificationData.CoinAmount.serializeBinaryToWriter
    );
  }
  f = message.getTxHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MonitorFundsCoinNotificationData.CoinAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MonitorFundsCoinNotificationData.CoinAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    denom: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MonitorFundsCoinNotificationData.CoinAmount}
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MonitorFundsCoinNotificationData.CoinAmount;
  return proto.api.MonitorFundsCoinNotificationData.CoinAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MonitorFundsCoinNotificationData.CoinAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MonitorFundsCoinNotificationData.CoinAmount}
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDenom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MonitorFundsCoinNotificationData.CoinAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MonitorFundsCoinNotificationData.CoinAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDenom();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string amount = 1;
 * @return {string}
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsCoinNotificationData.CoinAmount} returns this
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string denom = 2;
 * @return {string}
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.prototype.getDenom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsCoinNotificationData.CoinAmount} returns this
 */
proto.api.MonitorFundsCoinNotificationData.CoinAmount.prototype.setDenom = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.api.MonitorFundsCoinNotificationData.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsCoinNotificationData} returns this
 */
proto.api.MonitorFundsCoinNotificationData.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.api.MonitorFundsCoinNotificationData.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsCoinNotificationData} returns this
 */
proto.api.MonitorFundsCoinNotificationData.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CoinAmount amount = 3;
 * @return {!Array<!proto.api.MonitorFundsCoinNotificationData.CoinAmount>}
 */
proto.api.MonitorFundsCoinNotificationData.prototype.getAmountList = function() {
  return /** @type{!Array<!proto.api.MonitorFundsCoinNotificationData.CoinAmount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.api.MonitorFundsCoinNotificationData.CoinAmount, 3));
};


/**
 * @param {!Array<!proto.api.MonitorFundsCoinNotificationData.CoinAmount>} value
 * @return {!proto.api.MonitorFundsCoinNotificationData} returns this
*/
proto.api.MonitorFundsCoinNotificationData.prototype.setAmountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.api.MonitorFundsCoinNotificationData.CoinAmount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.api.MonitorFundsCoinNotificationData.CoinAmount}
 */
proto.api.MonitorFundsCoinNotificationData.prototype.addAmount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.api.MonitorFundsCoinNotificationData.CoinAmount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.api.MonitorFundsCoinNotificationData} returns this
 */
proto.api.MonitorFundsCoinNotificationData.prototype.clearAmountList = function() {
  return this.setAmountList([]);
};


/**
 * optional string tx_hash = 4;
 * @return {string}
 */
proto.api.MonitorFundsCoinNotificationData.prototype.getTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsCoinNotificationData} returns this
 */
proto.api.MonitorFundsCoinNotificationData.prototype.setTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.MonitorFundsTokenNotificationData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.MonitorFundsTokenNotificationData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.MonitorFundsTokenNotificationData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MonitorFundsTokenNotificationData.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    to: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contractAddr: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    txHash: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.MonitorFundsTokenNotificationData}
 */
proto.api.MonitorFundsTokenNotificationData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.MonitorFundsTokenNotificationData;
  return proto.api.MonitorFundsTokenNotificationData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.MonitorFundsTokenNotificationData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.MonitorFundsTokenNotificationData}
 */
proto.api.MonitorFundsTokenNotificationData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContractAddr(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.MonitorFundsTokenNotificationData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.MonitorFundsTokenNotificationData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.MonitorFundsTokenNotificationData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.MonitorFundsTokenNotificationData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContractAddr();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTxHash();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.api.MonitorFundsTokenNotificationData.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsTokenNotificationData} returns this
 */
proto.api.MonitorFundsTokenNotificationData.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to = 2;
 * @return {string}
 */
proto.api.MonitorFundsTokenNotificationData.prototype.getTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsTokenNotificationData} returns this
 */
proto.api.MonitorFundsTokenNotificationData.prototype.setTo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contract_addr = 3;
 * @return {string}
 */
proto.api.MonitorFundsTokenNotificationData.prototype.getContractAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsTokenNotificationData} returns this
 */
proto.api.MonitorFundsTokenNotificationData.prototype.setContractAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string amount = 4;
 * @return {string}
 */
proto.api.MonitorFundsTokenNotificationData.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsTokenNotificationData} returns this
 */
proto.api.MonitorFundsTokenNotificationData.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tx_hash = 5;
 * @return {string}
 */
proto.api.MonitorFundsTokenNotificationData.prototype.getTxHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.api.MonitorFundsTokenNotificationData} returns this
 */
proto.api.MonitorFundsTokenNotificationData.prototype.setTxHash = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.api.CrawlerData.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.api.CrawlerData.CrawlerDataCase = {
  CRAWLER_DATA_NOT_SET: 0,
  COSMOS: 1,
  COSMOS_EVM: 2,
  ETHEREUM: 3
};

/**
 * @return {proto.api.CrawlerData.CrawlerDataCase}
 */
proto.api.CrawlerData.prototype.getCrawlerDataCase = function() {
  return /** @type {proto.api.CrawlerData.CrawlerDataCase} */(jspb.Message.computeOneofCase(this, proto.api.CrawlerData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CrawlerData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CrawlerData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CrawlerData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CrawlerData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cosmos: (f = msg.getCosmos()) && proto.api.CosmosCrawlerData.toObject(includeInstance, f),
    cosmosEvm: (f = msg.getCosmosEvm()) && proto.api.CosmosEvmCrawlerData.toObject(includeInstance, f),
    ethereum: (f = msg.getEthereum()) && proto.api.EthCrawlerData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CrawlerData}
 */
proto.api.CrawlerData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CrawlerData;
  return proto.api.CrawlerData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CrawlerData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CrawlerData}
 */
proto.api.CrawlerData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CosmosCrawlerData;
      reader.readMessage(value,proto.api.CosmosCrawlerData.deserializeBinaryFromReader);
      msg.setCosmos(value);
      break;
    case 2:
      var value = new proto.api.CosmosEvmCrawlerData;
      reader.readMessage(value,proto.api.CosmosEvmCrawlerData.deserializeBinaryFromReader);
      msg.setCosmosEvm(value);
      break;
    case 3:
      var value = new proto.api.EthCrawlerData;
      reader.readMessage(value,proto.api.EthCrawlerData.deserializeBinaryFromReader);
      msg.setEthereum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CrawlerData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CrawlerData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CrawlerData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CrawlerData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCosmos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.CosmosCrawlerData.serializeBinaryToWriter
    );
  }
  f = message.getCosmosEvm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.CosmosEvmCrawlerData.serializeBinaryToWriter
    );
  }
  f = message.getEthereum();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.api.EthCrawlerData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CosmosCrawlerData cosmos = 1;
 * @return {?proto.api.CosmosCrawlerData}
 */
proto.api.CrawlerData.prototype.getCosmos = function() {
  return /** @type{?proto.api.CosmosCrawlerData} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosCrawlerData, 1));
};


/**
 * @param {?proto.api.CosmosCrawlerData|undefined} value
 * @return {!proto.api.CrawlerData} returns this
*/
proto.api.CrawlerData.prototype.setCosmos = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.api.CrawlerData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CrawlerData} returns this
 */
proto.api.CrawlerData.prototype.clearCosmos = function() {
  return this.setCosmos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CrawlerData.prototype.hasCosmos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CosmosEvmCrawlerData cosmos_evm = 2;
 * @return {?proto.api.CosmosEvmCrawlerData}
 */
proto.api.CrawlerData.prototype.getCosmosEvm = function() {
  return /** @type{?proto.api.CosmosEvmCrawlerData} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosEvmCrawlerData, 2));
};


/**
 * @param {?proto.api.CosmosEvmCrawlerData|undefined} value
 * @return {!proto.api.CrawlerData} returns this
*/
proto.api.CrawlerData.prototype.setCosmosEvm = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.api.CrawlerData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CrawlerData} returns this
 */
proto.api.CrawlerData.prototype.clearCosmosEvm = function() {
  return this.setCosmosEvm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CrawlerData.prototype.hasCosmosEvm = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional EthCrawlerData ethereum = 3;
 * @return {?proto.api.EthCrawlerData}
 */
proto.api.CrawlerData.prototype.getEthereum = function() {
  return /** @type{?proto.api.EthCrawlerData} */ (
    jspb.Message.getWrapperField(this, proto.api.EthCrawlerData, 3));
};


/**
 * @param {?proto.api.EthCrawlerData|undefined} value
 * @return {!proto.api.CrawlerData} returns this
*/
proto.api.CrawlerData.prototype.setEthereum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.api.CrawlerData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CrawlerData} returns this
 */
proto.api.CrawlerData.prototype.clearEthereum = function() {
  return this.setEthereum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CrawlerData.prototype.hasEthereum = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CosmosCrawlerData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CosmosCrawlerData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CosmosCrawlerData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosCrawlerData.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedBlocks: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CosmosCrawlerData}
 */
proto.api.CosmosCrawlerData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CosmosCrawlerData;
  return proto.api.CosmosCrawlerData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CosmosCrawlerData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CosmosCrawlerData}
 */
proto.api.CosmosCrawlerData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProcessedBlocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CosmosCrawlerData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CosmosCrawlerData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CosmosCrawlerData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosCrawlerData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedBlocks();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 processed_blocks = 1;
 * @return {number}
 */
proto.api.CosmosCrawlerData.prototype.getProcessedBlocks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.CosmosCrawlerData} returns this
 */
proto.api.CosmosCrawlerData.prototype.setProcessedBlocks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.CosmosEvmCrawlerData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.CosmosEvmCrawlerData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.CosmosEvmCrawlerData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosEvmCrawlerData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cosmos: (f = msg.getCosmos()) && proto.api.CosmosCrawlerData.toObject(includeInstance, f),
    ethereum: (f = msg.getEthereum()) && proto.api.EthCrawlerData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.CosmosEvmCrawlerData}
 */
proto.api.CosmosEvmCrawlerData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.CosmosEvmCrawlerData;
  return proto.api.CosmosEvmCrawlerData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.CosmosEvmCrawlerData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.CosmosEvmCrawlerData}
 */
proto.api.CosmosEvmCrawlerData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.api.CosmosCrawlerData;
      reader.readMessage(value,proto.api.CosmosCrawlerData.deserializeBinaryFromReader);
      msg.setCosmos(value);
      break;
    case 2:
      var value = new proto.api.EthCrawlerData;
      reader.readMessage(value,proto.api.EthCrawlerData.deserializeBinaryFromReader);
      msg.setEthereum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.CosmosEvmCrawlerData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.CosmosEvmCrawlerData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.CosmosEvmCrawlerData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.CosmosEvmCrawlerData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCosmos();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.api.CosmosCrawlerData.serializeBinaryToWriter
    );
  }
  f = message.getEthereum();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.api.EthCrawlerData.serializeBinaryToWriter
    );
  }
};


/**
 * optional CosmosCrawlerData cosmos = 1;
 * @return {?proto.api.CosmosCrawlerData}
 */
proto.api.CosmosEvmCrawlerData.prototype.getCosmos = function() {
  return /** @type{?proto.api.CosmosCrawlerData} */ (
    jspb.Message.getWrapperField(this, proto.api.CosmosCrawlerData, 1));
};


/**
 * @param {?proto.api.CosmosCrawlerData|undefined} value
 * @return {!proto.api.CosmosEvmCrawlerData} returns this
*/
proto.api.CosmosEvmCrawlerData.prototype.setCosmos = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmCrawlerData} returns this
 */
proto.api.CosmosEvmCrawlerData.prototype.clearCosmos = function() {
  return this.setCosmos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmCrawlerData.prototype.hasCosmos = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EthCrawlerData ethereum = 2;
 * @return {?proto.api.EthCrawlerData}
 */
proto.api.CosmosEvmCrawlerData.prototype.getEthereum = function() {
  return /** @type{?proto.api.EthCrawlerData} */ (
    jspb.Message.getWrapperField(this, proto.api.EthCrawlerData, 2));
};


/**
 * @param {?proto.api.EthCrawlerData|undefined} value
 * @return {!proto.api.CosmosEvmCrawlerData} returns this
*/
proto.api.CosmosEvmCrawlerData.prototype.setEthereum = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.api.CosmosEvmCrawlerData} returns this
 */
proto.api.CosmosEvmCrawlerData.prototype.clearEthereum = function() {
  return this.setEthereum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.api.CosmosEvmCrawlerData.prototype.hasEthereum = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.api.EthCrawlerData.prototype.toObject = function(opt_includeInstance) {
  return proto.api.EthCrawlerData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.api.EthCrawlerData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.EthCrawlerData.toObject = function(includeInstance, msg) {
  var f, obj = {
    processedBlocks: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.api.EthCrawlerData}
 */
proto.api.EthCrawlerData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.api.EthCrawlerData;
  return proto.api.EthCrawlerData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.api.EthCrawlerData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.api.EthCrawlerData}
 */
proto.api.EthCrawlerData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setProcessedBlocks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.api.EthCrawlerData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.api.EthCrawlerData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.api.EthCrawlerData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.api.EthCrawlerData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessedBlocks();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 processed_blocks = 1;
 * @return {number}
 */
proto.api.EthCrawlerData.prototype.getProcessedBlocks = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.api.EthCrawlerData} returns this
 */
proto.api.EthCrawlerData.prototype.setProcessedBlocks = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.api.AlertSource = {
  COSMOSMSG: 0,
  COSMOSTX: 1,
  ETHLOG: 2,
  ETHTX: 3
};

goog.object.extend(exports, proto.api);
