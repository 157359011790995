import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as Delete } from '@/assets/icons/Delete.svg'
import { Box, IconButton, Modal, styled, Typography } from '@mui/material'
import { StyledButton } from '../StyledButton/StyledButton'

type DeleteConfirmationModalProps = {
  open: boolean
  onCloseModal: () => void
  type: 'ALERT' | 'DESTINATION'
  onDelete: () => void
  data?: any
}

export default function DeleteConfirmationModal({
  open,
  onCloseModal,
  type,
  onDelete,
  data
}: DeleteConfirmationModalProps) {
  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #FFFFFF3D',
            padding: '16px',
          }}
        >
          <Typography
            color="white"
            textAlign={'left'}
            sx={{
              width: '100%',
              fontSize: '22px',
              fontWeight: 600,
            }}
          >
            Delete {type === 'ALERT' ? 'Alert' : 'Destination'}
          </Typography>
          <IconButton
            onClick={onCloseModal}
            sx={{
              border: '1px solid #FFFFFF3D',
              borderRadius: '8px',
              padding: '6px',
            }}
          >
            <CloseIcon
              sx={{
                color: 'white',
                fontSize: '20px',
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: '16px',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              fontSize: '16px',
              fontWeight: 400
            }}
          >
            This action cannot be undone.
          </Typography>
          <Typography
            sx={{
                color: 'white',
                marginBottom: '10px',
                fontSize: '16px',
                fontWeight: 400
            }}
          >
            Are you sure you want to delete this{type === 'ALERT' ? ' alert?' : ' destination?'}{' '}
          </Typography>
          {data}
          <Box display={'flex'} gap={'12px'} mt={'20px'}>
            <StyledButton
              sx={{
                backgroundColor: 'transparent !important',
                border: '1px solid #FF4D6C3D !important',
                borderRadius: '8px',
                color: '#FF4D6C !important',
                '&:hover': {
                  backgroundColor: '#FFFFFF3D !important',
                },
              }}
              onClick={onDelete}
            >
                <Delete />
              Delete
            </StyledButton>
            <StyledButton
              sx={{
                borderRadius: '8px',
                backgroundColor: 'transparent !important',
                border: '1px solid #FFFFFF3D !important',
                color: 'white !important',
              }}
              onClick={onCloseModal}
            >
              Cancel
            </StyledButton>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  )
}

const ModalStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 'auto',
  width: 500,
  backgroundColor: '#353535',
  padding: '0',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
}))
