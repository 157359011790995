import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Typography } from '@mui/material'
import FormInput from '../FormInput/FormInput'
import { StyledButton } from '../StyledButton/StyledButton'

type ConfirmDestinationViewProps = {
  title: string
  destination?: string
  confirmDestination: () => Promise<boolean | undefined>
  onCloseModal: () => void
  onSubmit: () => void
}

export default function ConfirmDestinationView({
  title,
  destination,
  confirmDestination,
  onCloseModal,
  onSubmit,
}: ConfirmDestinationViewProps) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          borderBottom: '1px solid #FFFFFF3D',
          padding: '16px',
        }}
      >
        <Typography
          color="white"
          textAlign={'left'}
          sx={{
            width: '100%',
            fontSize: '22px',
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={onCloseModal}
          sx={{
            border: '1px solid #FFFFFF3D',
            borderRadius: '8px',
            padding: '6px',
          }}
        >
          <CloseIcon
            sx={{
              color: 'white',
              fontSize: '20px',
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          padding: '16px',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            marginBottom: '10px',
            fontSize: '16px',
          }}
        >
          A verification code was send to{' '}
          <span
            style={{
              fontSize: '16px',
              fontWeight: 800,
            }}
          >
            {destination}
          </span>
          . Paste it below to finish setting up this destination.
        </Typography>
        <Typography
          sx={{
            color: 'white',
            fontSize: '14px',
            fontWeight: 600,
            paddingBottom: '6px',
          }}
        >
          Enter verification code{' '}
        </Typography>
        <FormInput name="confirmationCode" placeholder="0000" />
        <Box display={'flex'} gap={'12px'} mt={'20px'}>
          <StyledButton onClick={confirmDestination}>Verify</StyledButton>
          <StyledButton
            sx={{
              backgroundColor: 'transparent !important',
              border: '1px solid #FFFFFF3D !important',
              color: 'white !important',
              '&:hover': {
                backgroundColor: '#FFFFFF3D !important',
              },
            }}
            onClick={onSubmit}
          >
            Resend code
          </StyledButton>
        </Box>
      </Box>
    </>
  )
}
