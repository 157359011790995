import { colors } from '@/styles/colorScheme'
import { typography } from '@/styles/typography'
import { Box, styled, TextField, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

type FormInputProps = {
  name: string
  placeholder?: string
  disabled?: boolean
  label?: string
  required?: boolean
  multiline?: boolean
  rows?: number
}

export default function FormInput({
  name,
  placeholder,
  disabled,
  label,
  required,
  multiline,
  rows,
}: FormInputProps) {
  const { control } = useFormContext()
  return (
    <Box width={'100%'}>
      {label && <StyleLabel>{label}</StyleLabel>}
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field, fieldState }) => (
          <Input
            className={multiline ? 'custom-font' : ''}
            rows={rows}
            multiline={multiline}
            placeholder={placeholder}
            disabled={disabled}
            error={Boolean(fieldState.error)}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            required={required}
          />
        )}
      />
    </Box>
  )
}

const Input = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: '#61E89F',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#61E89F',
    },
  },
  width: '100%',
  border: '1px solid rgba(255, 255, 255, 0.282);',
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: '8px',
  '&.Mui-focused fieldset': {
    borderColor: 'green',
  },
  '& fieldset': {
    borderColor: 'none',
  },
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
  '& .Mui-disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.24)',
    '&:hover': {
      border: 'none',
    },
  },
  '& input': {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255,255,255,0.65)',
    padding: '12px',
    height: '40px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
  '&.custom-font': {
    '& .MuiOutlinedInput-root': {
      color: '#61E89F',
      fontFamily: 'Source Code Pro',
    },
  },
}))

const StyleLabel = styled(Typography)(() => ({
  marginBottom: '10px',
  color: colors.white.primary,
  ...typography.subtitleRegular,
}))
