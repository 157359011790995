import { useState } from 'react'
import { useBoolean } from 'usehooks-ts'
import { DestinationType, FinishSetupDestination } from '../types/common.types'

export default function useCommonDestinationState() {
  const { value: confirmationModalOpen, setTrue, setFalse } = useBoolean(false)
  const [proposalDestination, setProposalDestination] = useState<FinishSetupDestination>()

  const updateProposalDestination = (confirmProposalId: string, type: DestinationType, value: string) => {
    setProposalDestination({
      confirmProposalId: confirmProposalId,
      type: type,
      value: value,
    })
  }

  const clearNotFinishedDestination = () => {
    setProposalDestination(undefined)
  }

  const openModal = () => {
    setTrue()
  }

  return {
    confirmationModalOpen,
    openModal,
    closeModal: setFalse,
    updateProposalDestination,
    clearNotFinishedDestination,
    proposalDestination,
  }
}
