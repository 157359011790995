import { ReactComponent as Telegram } from '@/assets/icons/Telegram.svg'
import { ReactComponent as Webhook } from '@/assets/icons/Webhook.svg'
import { ReactComponent as DiscordDestinationLogo } from '@/assets/icons/DiscordDestinationLogo.svg'
import useCreateDestination from '@/common/hooks/useCreateDestination'
import { DestinationType, FinishSetupDestination } from '@/common/types/common.types'
import { pxToRem } from '@/common/utils'
import { UserDestination } from '@/proto/api_pb'
import { colors } from '@/styles/colorScheme'
import { Button, styled } from '@mui/material'
import React, { useEffect } from 'react'
import ConfirmationModal from '../Modal/ConfirmationModal'
import TelegramBotModal from '../Modal/TelegramBotModal'

type CreateDestinationProps = {
  onUpdateProposalDestination: (
    confirmProposalId: string,
    type: DestinationType,
    value: string
  ) => void
  proposalDestination?: FinishSetupDestination
  columnView?: boolean
  allUserDestinations: UserDestination.AsObject[]
  updateSelectIds: (id: string) => void
  confirmationModalOpen: boolean
  onOpenModal: () => void
  onCloseModal: () => void
  clearNotFinishedDestination: () => void
}

export default function CreateDestination({
  onUpdateProposalDestination,
  proposalDestination,
  columnView,
  allUserDestinations,
  updateSelectIds,
  confirmationModalOpen,
  onOpenModal,
  onCloseModal,
  clearNotFinishedDestination,
}: CreateDestinationProps) {
  const {
    openCreateModal,
    openTelegramModal,
    openTelegram,
    closeTelegramModal,
    confirmTelegram,
    destinationType,
    methods,
    submitDestination,
    confirmDestination,
    reSubmitDestination,
    reset,
    checkIsSameDestination,
    updateProposalDestination,
  } = useCreateDestination(onUpdateProposalDestination)

  useEffect(() => {
    if (proposalDestination) {
      updateProposalDestination(proposalDestination)
    }
  }, [proposalDestination, updateProposalDestination])

  const handleOpenCreateModal = (destinationType: DestinationType) => {
    openCreateModal(destinationType)
    onOpenModal()
  }

  const handleConfirmTelegram = () => {
    confirmTelegram()
    onOpenModal()
  }

  return (
    <>
      <Container isColumn={columnView}>
        <DestinationButton
          noBorder={columnView}
          startIcon={<Webhook />}
          onClick={() => handleOpenCreateModal('WEBHOOK')}
        >
          Webhook
        </DestinationButton>
        {/* <DestinationButton startIcon={<Email />} onClick={() => handleOpen('EMAIL')}>
          Email
        </DestinationButton> */}
        <DestinationButton
          noBorder={columnView}
          startIcon={<Telegram />}
          onClick={() => openTelegramModal()}
        >
          Telegram
        </DestinationButton>
        <DestinationButton
          noBorder={columnView}
          startIcon={<DiscordDestinationLogo />}
          onClick={() => handleOpenCreateModal('DISCORD')}
        >
          Discord
        </DestinationButton>
      </Container>
      <TelegramBotModal
        open={openTelegram}
        handleClose={closeTelegramModal}
        confirmFn={handleConfirmTelegram}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        handleClose={onCloseModal}
        destinationType={destinationType}
        allUserDestinations={allUserDestinations}
        methods={methods}
        onSubmit={submitDestination}
        onConfirm={confirmDestination}
        reSubmit={reSubmitDestination}
        reset={reset}
        updateSelectIds={updateSelectIds}
        checkIsSameDestination={checkIsSameDestination}
        proposalDestination={proposalDestination}
        clearNotFinishedDestination={clearNotFinishedDestination}
      />
    </>
  )
}

const Container = styled('div', {
  shouldForwardProp: prop => prop !== 'isColumn',
})<{ isColumn?: boolean }>(({ isColumn }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  gap: pxToRem(20),
  ...(isColumn && {
    flexDirection: 'column',
    gap: 0,
  }),
}))

const DestinationButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'noBorder',
})<{ noBorder?: boolean }>(({ noBorder }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px 8px 12px',
  gap: '6px',
  width: 'fit-content',
  height: '40px',
  color: colors.white.primary,
  fontSize: '16px',
  border: '1px solid rgba(255, 255, 255, 0.24)',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.24)',
  },
  ...(noBorder && {
    borderColor: 'transparent',
  }),
}))
