import { useAuth } from '@/contexts/AuthContext'
import { AuthContextType } from '@/contexts/AuthContextType'
import { useSnackbar } from '@/contexts/SnackbarContext'
import { SnackBarContextType } from '@/contexts/SnackbarContextType'
import { UserDestination } from '@/proto/api_pb'
import { userService } from '@/service/UserService'
import { yupResolver } from '@hookform/resolvers/yup'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { DestinationType, FinishSetupDestination } from '../types/common.types'
import { alertMessages } from '../utils/alert-messages'
import { validationSchema } from '../validations/create-destination.validation'

const defaultValues = {
  email: '',
  webhook: '',
  telegram: '',
  discord: '',
  confirmationCode: '',
}

export default function useCreateDestination(
  onUpdateProposalDestination: (id: string, type: DestinationType, value: string) => void
) {
  const [destinationType, setDestinationType] = useState<DestinationType>('EMAIL')
  const [openTelegram, setOpenTelegram] = useState(false)
  const [proposalDestination, setProposalDestination] = useState<FinishSetupDestination>({
    confirmProposalId: '',
  })
  const { addAlert } = useSnackbar() as SnackBarContextType
  const router = useRouter()
  const { userToken, userId } = useAuth() as AuthContextType

  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const { watch, reset } = methods
  const [telegram, email, webhook, discord, confirmationCode] = watch([
    'telegram',
    'email',
    'webhook',
    'discord',
    'confirmationCode',
  ])

  const checkIsSameDestination = (allUserDestinations: UserDestination.AsObject[]) => {
    const isSame = allUserDestinations.find((destination: UserDestination.AsObject) => {
      return (
        destination.destination?.telegram === telegram &&
        destination.destination?.email === email &&
        destination.destination?.webhook === webhook &&
        destination.destination?.discordWebhook === discord
      )
    })
    if (isSame) addAlert(alertMessages.destinations.isSameDestination)
    return isSame
  }

  const submitDestination = async (type: DestinationType): Promise<boolean> => {
    if (!userId || !userToken) {
      router.push('/login')
      return false
    }

    try {
      let value =
        type === 'EMAIL'
          ? email
          : type === 'WEBHOOK'
          ? webhook
          : type === 'TELEGRAM'
          ? telegram
          : discord
      let response = await userService.createDestination(type, value, userId, userToken)
      if (response?.status == 'FAIL') {
        addAlert(alertMessages.destinations.invalidTelegramUsername)
        console.log('response error ---', response)
        return false
      }
      if (response?.status == 'SUCCESS') {
        onUpdateProposalDestination(response.data, type, value)
        setProposalDestination({
          confirmProposalId: response.data,
          type: type,
          value: value,
        })
        addAlert(alertMessages.destinations.destinationConfirmationCode)
        return true
      }
      type === 'TELEGRAM'
        ? addAlert(alertMessages.destinations.invalidTelegramUsername)
        : addAlert(alertMessages.generalError)
      return false
    } catch (error) {
      console.log('error', error)
      addAlert(alertMessages.generalError)
      return false
    }
  }

  const reSubmitDestination = async (): Promise<boolean> => {
    if (!userId || !userToken) {
      router.push('/login')
      return false
    }
    try {
      if (proposalDestination.value && proposalDestination.type) {
        const response = await userService.createDestination(
          proposalDestination.type,
          proposalDestination.value,
          userId,
          userToken
        )
        if (response?.status == 'SUCCESS') {
          setProposalDestination({
            confirmProposalId: response.data,
            type: proposalDestination.type,
            value: proposalDestination.value,
          })
          onUpdateProposalDestination(
            response.data,
            proposalDestination.type,
            proposalDestination.value
          )
          addAlert(alertMessages.destinations.destinationNewConfirmationCode)
          return true
        }
      }
      addAlert(alertMessages.generalError)
      return false
    } catch (error) {
      console.log('error', error)
      addAlert(alertMessages.generalError)
      return false
    }
  }

  const confirmDestination = async (): Promise<string | boolean> => {
    if (!userId || !userToken) {
      router.push('/login')
      return false
    }
    try {
      if (proposalDestination.confirmProposalId) {
        const response = await userService.confirmDestination(
          proposalDestination.confirmProposalId,
          confirmationCode,
          userId,
          userToken
        )
        if (response?.status == 'SUCCESS') {
          addAlert(alertMessages.destinations.destinationCreated)
          return response.data.destination.id
        }
      }
      addAlert(alertMessages.destinations.destinationNotCreated)
      return false
    } catch (error) {
      addAlert(alertMessages.destinations.destinationNotCreated)
      return false
    }
  }

  const openTelegramModal = () => setOpenTelegram(true)
  const closeTelegramModal = () => setOpenTelegram(false)
  const confirmTelegram = () => {
    setOpenTelegram(false)
    openCreateModal('TELEGRAM')
  }

  const openCreateModal = (destinationType: DestinationType) => {
    onUpdateProposalDestination('', '', '')
    reset()
    setDestinationType(destinationType)
  }

  const updateProposalDestination = (newProposal: FinishSetupDestination) => {
    setProposalDestination(newProposal)
  }

  return {
    openCreateModal,
    openTelegram,
    openTelegramModal,
    closeTelegramModal,
    confirmTelegram,
    destinationType: proposalDestination.type ? proposalDestination.type : destinationType,
    methods,
    submitDestination,
    confirmDestination,
    reSubmitDestination,
    reset,
    checkIsSameDestination,
    proposalDestination,
    updateProposalDestination,
  }
}
