import { ReactComponent as Email } from '@/assets/icons/Mail.svg'
import { ReactComponent as Telegram } from '@/assets/icons/Telegram.svg'
import { ReactComponent as Webhook } from '@/assets/icons/Webhook.svg'
import DeleteConfirmationModal from '@/common/components/DeleteConfirmationModal/DeleteConfirmationModal'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, IconButton, styled, Switch, SwitchProps, Typography } from '@mui/material'
import { useState } from 'react'
import AlertPopover from './AlertPopover'

type DestinationRowProps = {
  destinationId: string
  destinationType?: string
  label?: string
  finishSetup: boolean
  active?: boolean
  handleOpenConfirmationModal?: () => void
  cleanDestinationData?: () => void
  disableToggle?: boolean
  handleActiveChange?: (id: string) => void
  deleteDestination?: (id: string) => void
}

export default function DestinationRow({
  destinationId,
  destinationType,
  label,
  finishSetup,
  active,
  handleOpenConfirmationModal,
  cleanDestinationData,
  disableToggle,
  handleActiveChange,
  deleteDestination,
}: DestinationRowProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toogleActive = () => {
    handleActiveChange && handleActiveChange(destinationId)
  }

  const handleDeleteDestination = () => {
    if (deleteDestination) {
      deleteDestination(destinationId)
      handleClose()
      closeModal()
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const idPopover = open ? 'simple-popover' : undefined
  return (
    <DestinationRowStyle className={finishSetup ? '' : 'notFinished'}>
      <DestinationRowContentStyle
        className={'box'}
        onClick={
          !finishSetup && handleOpenConfirmationModal
            ? () => handleOpenConfirmationModal()
            : () => {}
        }
      >
        <DestinationType>
          {destinationType == 'EMAIL' && (
            <>
              <Email /> Mail
            </>
          )}
          {destinationType == 'WEBHOOK' && (
            <>
              <Webhook />
              Webhook
            </>
          )}
          {destinationType == 'TELEGRAM' && (
            <>
              <Telegram />
              Telegram
            </>
          )}
          {destinationType == 'DISCORD' && (
            <>
              <Telegram />
              Discord Webhook
            </>
          )}
        </DestinationType>
        <Typography
          sx={{
            color: 'white',
            textOverflow: 'ellipsis',
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
            borderRadius: '4px',
            display: 'flex',
            flexWrap: 'wrap',
            padding: '2px 10px',
          }}
        >
          {label}
        </Typography>
        {!finishSetup && (
          <BadgeStyle>
            <span>Finish Setup</span>
          </BadgeStyle>
        )}
      </DestinationRowContentStyle>
      {!disableToggle ? (
        <DestinationRowCreateAlertStyle>
          {finishSetup && (
            <>
              <Typography>{active ? 'Active' : 'Disabled'}</Typography>
              <MempoolsSwitch value={''} checked={active} onChange={toogleActive}/>
            </>
          )}
          <IconButton
            onClick={handleClick}
            sx={{
              padding: '0px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {!finishSetup && (
              <MoreHorizIcon
                sx={{
                  color: 'rgba(255, 255, 255, 0.48)',
                  fontSize: '24px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: 'rgba(255, 255, 255, 0.65)',
                  },
                }}
              />
            )}
          </IconButton>
          <AlertPopover
            handleClose={handleClose}
            anchorEl={anchorEl}
            open={open}
            id={idPopover}
            justDelete={true}
            onDeleteFn={cleanDestinationData}
          />
        </DestinationRowCreateAlertStyle>
      ) : (
        <DestinationRowCreateAlertStyle>
          <IconButton
            onClick={handleClick}
            sx={{
              padding: '0px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <MoreHorizIcon
              sx={{
                color: 'rgba(255, 255, 255, 0.48)',
                fontSize: '24px',
                cursor: 'pointer',
                '&:hover': {
                  color: 'rgba(255, 255, 255, 0.65)',
                },
              }}
            />
          </IconButton>
          <AlertPopover
            handleClose={handleClose}
            anchorEl={anchorEl}
            open={open}
            id={idPopover}
            justDelete={true}
            onDeleteFn={openModal}
          />
        </DestinationRowCreateAlertStyle>
      )}
      <DeleteConfirmationModal
        open={isModalOpen}
        onCloseModal={closeModal}
        onDelete={handleDeleteDestination}
        type="DESTINATION"
        data={
          <DestinationRowStyle
            sx={{
              backgroundColor: 'transparent !important',
              border: '1px solid #FFFFFF1F !important',
            }}
          >
            <DestinationType>
              {destinationType == 'EMAIL' && (
                <>
                  <Email /> Mail
                </>
              )}
              {destinationType == 'WEBHOOK' && (
                <>
                  <Webhook />
                  Webhook
                </>
              )}
              {destinationType == 'TELEGRAM' && (
                <>
                  <Telegram />
                  Telegram
                </>
              )}
              {destinationType == 'DISCORD' && (
                <>
                  <Webhook />
                  Discord Webhook
                </>
              )}
            </DestinationType>
            <Typography
              sx={{
                color: 'white',
                textOverflow: 'ellipsis',
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                borderRadius: '4px',
                display: 'flex',
                flexWrap: 'wrap',
                padding: '2px 10px',
              }}
            >
              {label}
            </Typography>
          </DestinationRowStyle>
        }
      />
    </DestinationRowStyle>
  )
}

const DestinationRowStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
  display: 'flex',
  borderRadius: '8px',
  padding: '12px',
  color: 'white',
  gap: '12px',
  flexWrap: 'wrap',

  '&:hover': {
    borderColor: '#61E89F',
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },

  '&.focused': {
    borderColor: '#61E89F',
    boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },

  '& input': {
    background: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255,255,255,0.65)',
    borderRadius: '8px',
    padding: '12px',
    height: '40px',
    boxSizing: 'border-box',
    width: '0',
    minWidth: '30px',
    flexGrow: '1',
    border: 0,
    margin: 0,
    outline: 0,
  },

  '&.notFinished': {
    backgroundColor: 'rgba(255, 234, 124, 0.24)',
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 234, 124, 0.48)',
    },
  },
  '&.notFinished > .box': {
    cursor: 'pointer',
  },

  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}))

const DestinationRowContentStyle = styled(Box)(({ theme }) => ({
  width: 'auto',
  display: 'flex',
  gap: '12px',
  flexWrap: 'wrap',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}))

const DestinationRowCreateAlertStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  gap: '9px',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}))

const DestinationType = styled(Typography)(
  () => `
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
        `
)

const BadgeStyle = styled('div')(
  () => `
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 8px;
      padding: 2px 10px;
      color: white;
      font-size: 14px;
      `
)

const MempoolsSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#000000',
      '& + .MuiSwitch-track': {
        backgroundColor: '#61E89F',
        opacity: 1,
        border: 0,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'black',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    opacity: "100% !important"
  },
}))
