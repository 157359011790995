import * as yup from 'yup'

export const validationSchema = yup.object().shape(
  {
    discord: yup.string().when(['webhook', 'telegram'], {
      is: (value: string, value1: string) => !value && !value1,
      then: yup.string().url('This is not a valid discord webhook url').required('This field is required'),
    }),
    webhook: yup.string().when(['discord', 'telegram'], {
      is: (value: string, value1: string) => !value && !value1,
      then: yup.string().url('This is not a valid url').required('This field is required'),
    }),
    telegram: yup.string().when(['discord', 'webhook'], {
      is: (value: string, value1: string) => !value && !value1,
      then: yup.string().required('This field is required'),
    }),
  },
  [
    ['webhook', 'telegram'],
    ['discord', 'telegram'],
    ['discord', 'webhook'],
  ]
)
