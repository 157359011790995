import { ReactComponent as Delete } from '@/assets/icons/Delete.svg'
import { ReactComponent as Duplicate } from '@/assets/icons/Duplicate.svg'
import { ReactComponent as Edit } from '@/assets/icons/Edit.svg'
import { EditAlertData } from '@/common/types/common.types'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import Popover from '@mui/material/Popover'
import Link from 'next/link'

type AlertPopoverProps = {
  anchorEl: Element | ((element: Element) => Element) | null | undefined
  handleClose: () => void
  open: boolean
  id?: string
  justDelete?: boolean
  onDeleteFn: (() => void) | undefined
  alertData?: EditAlertData
}

export default function AlertPopover({
  anchorEl,
  handleClose,
  open,
  id,
  justDelete,
  onDeleteFn,
  alertData,
}: AlertPopoverProps) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPopover-paper': {
          backgroundColor: '#3D3D3D',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          padding: '8px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          marginLeft: '18px',
          marginTop: '2px',
        },
      }}
    >
      {!justDelete && (
        <>
          <Link
            style={{
              width: '100%',
            }}
            href={{
              pathname: '/edit',
              query: alertData,
            }}
          >
            <PopoverButton>
              <Edit /> Edit
            </PopoverButton>
          </Link>
          <Link
            style={{
              width: '100%',
            }}
            href={{
              pathname: '/create',
              query: alertData,
            }}
          >
            <PopoverButton>
              {' '}
              <Duplicate /> Duplicate
            </PopoverButton>
          </Link>
        </>
      )}
      <PopoverButton
        sx={{
          color: '#FF4D6C !important',
        }}
        onClick={onDeleteFn}
      >
        <Delete />
        Delete
      </PopoverButton>
    </Popover>
  )
}

const PopoverButton = styled(Button)(() => ({
  width: '100%',
  height: 'auto',
  backgroundColor: 'transparent',
  color: '#FFF',
  padding: '10px 16px',
  gap: '10px',
  justifyContent: 'flex-start',
  borderRadius: '8px',
  fontSize: '16px',
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: 'rgba(255, 255, 255, 0.24)',
  },
  '&:hover': {
    backgroundColor: '#FFFFFF1F',
  },
  '&:active': {
    backgroundColor: '#FFFFFF1F',
  },
  '&:focus': {
    backgroundColor: '#FFFFFF1F',
  },
}))
