import { ReactComponent as CheckCircle } from '@/assets/icons/CheckCircle.svg'
import { ReactComponent as Cross } from '@/assets/icons/Cross.svg'
import { ReactComponent as SmartContract } from '@/assets/icons/SmartContract.svg'
import { ReactComponent as Avatar } from '@/assets/icons/Avatar.svg'
import { ReactComponent as Repeat } from '@/assets/icons/Repeat.svg'
import { ReactComponent as ArrowRight } from '@/assets/icons/ArrowRight.svg'
import { ReactComponent as Watch } from '@/assets/icons/Watch.svg'
import { Box, styled } from '@mui/material'
import { AlertTypeEnum, EthAlertTypeEnum, EvmAlertTypeEnum } from '@/common/enums/AlertTypeEnum'
import { AlertData } from '@/common/types/common.types'

const Icon = ({ icon }: any) => {
  return <StyledIcon>{icon}</StyledIcon>
}

const StyledIcon = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    '& path': {
      strokeOpacity: 0.48,
    },
  },
}))

export const alertsData = {
  cosmosAlerts: [
    {
      id: AlertTypeEnum.MONITOR_FUNDS,
      name: 'Monitor Funds',
      icon: <Icon icon={<Watch />} />,
    },
    {
      id: AlertTypeEnum.SMART_CONTRACT_EVENTS,
      name: 'Smart Contract Event',
      icon: <Icon icon={<SmartContract />} />,
    },
    {
      id: AlertTypeEnum.TX_OUTCOME,
      name: 'Transaction',
      icon: <Icon icon={<Repeat />} />,
    },
    {
      id: AlertTypeEnum.SEND_FUNDS,
      name: 'Send Funds',
      icon: <Icon icon={<ArrowRight />} />,
    },
  ] as unknown as AlertData[],
  evmAlerts: [
    {
      id: EvmAlertTypeEnum.EVM_MONITOR_FUNDS,
      name: 'Monitor Funds EVM',
      icon: <Icon icon={<Watch />} />,
    },
    {
      id: EvmAlertTypeEnum.EVM_TX_OUTCOME,
      name: 'EVM Transaction',
      icon: <Icon icon={<Repeat />} />,
    },
    {
      id: EvmAlertTypeEnum.EVM_SMART_CONTRACT_EVENTS,
      name: 'EVM Smart Contract Event',
      icon: <Icon icon={<SmartContract />} />,
    },
    {
      id: EvmAlertTypeEnum.COSMOS_MONITOR_FUNDS,
      name: 'Monitor Funds Cosmos',
      icon: <Icon icon={<Watch />} />,
    },
    {
      id: EvmAlertTypeEnum.COSMOS_TX_OUTCOME,
      name: 'Cosmos Transaction',
      icon: <Icon icon={<Repeat />} />,
    },
  ] as unknown as AlertData[],
  ethAlerts: [
    {
      id: EthAlertTypeEnum.ETH_MONITOR_FUNDS,
      name: 'Monitor Funds EVM',
      icon: <Icon icon={<Watch />} />,
    },
    {
      id: EthAlertTypeEnum.ETH_TX_OUTCOME,
      name: 'ETH Transaction',
      icon: <Icon icon={<Repeat />} />,
    },
    {
      id: EthAlertTypeEnum.ETH_SMART_CONTRACT_EVENTS,
      name: 'ETH Smart Contract Event',
      icon: <Icon icon={<SmartContract />} />,
    },
  ] as unknown as AlertData[],
}
