import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Modal, styled, Typography } from '@mui/material'
import Link from 'next/link'
import { StyledButton } from '../StyledButton/StyledButton'
import { ReactComponent as LinkIcon } from '@/assets/icons/Link.svg'

type TelegramBotModalProps = {
  handleClose: () => void
  open: boolean
  confirmFn: () => void
}

export default function TelegramBotModal({ handleClose, open, confirmFn }: TelegramBotModalProps) {
  const onCloseModal = () => {
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle>
        <Box
          sx={{
            display: 'flex',
            borderBottom: '1px solid #FFFFFF3D',
            padding: '16px',
          }}
        >
          <Typography
            color="white"
            textAlign={'left'}
            sx={{
              width: '100%',
              fontSize: '22px',
              fontWeight: 600,
            }}
          >
            Telegram destination
          </Typography>
          <IconButton
            onClick={onCloseModal}
            sx={{
              border: '1px solid #FFFFFF3D',
              borderRadius: '8px',
              padding: '6px',
            }}
          >
            <CloseIcon
              sx={{
                color: 'white',
                fontSize: '20px',
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: '14px',
          }}
        >
          <Typography
            sx={{
              color: 'white',
              marginBottom: '10px',
              fontSize: '16px',
            }}
          >
            To complete the setup for this destination, you will need to connect to our Telegram
            bot.
          </Typography>
          <Box display={'flex'} gap={'12px'} mt={'20px'}>
            <Link href={process.env.TELEGRAM_URL as string} target="_blank" rel="noreferrer">
              <StyledButton onClick={confirmFn}>
                Open Telegram
                <LinkIcon />
              </StyledButton>
            </Link>
          </Box>
        </Box>
      </ModalStyle>
    </Modal>
  )
}
const ModalStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  margin: 'auto',
  width: 500,
  backgroundColor: '#353535',
  padding: '0',
  borderRadius: '8px',
  [theme.breakpoints.down('md')]: {
    width: '350px',
  },
}))
